import { useState } from 'react';
import { Box, Grid, Stack, Button, IconButton, Chip, TextField, Grow } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import { useQuery } from '@tanstack/react-query';
import TableContainer from 'src/components/Table';
import { GridColDef } from '@mui/x-data-grid';
import { tratandoData, tratandoValorCentavos } from 'src/config/utils';
import SearchIcon from '@mui/icons-material/Search';
import { getPlanoAplicacao } from 'src/services/Planejamento/FundoNacional/planoAplicacao';
import AtualizarPlano from './Atualizar';

export default function PlanoAplicacao() {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 9,
  });

  const skip = paginationModel.page * paginationModel.pageSize;
  const take = paginationModel.pageSize;

  const [id, setId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setmodalType] = useState<string>();
  const [checked, setChecked] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');
  const include = ['eixoFinanciadoCount', 'user'];

  const {
    data: rows,
    refetch: RefecthPlano,
    isPending,
  } = useQuery({
    queryKey: ['getPlanoAplicacao', skip, take, search, include],
    queryFn: getPlanoAplicacao,
  });

  const handleButtonClick = (idPlano: string, type: string) => {
    setId(idPlano);
    setIsModalOpen(true);
    setmodalType(type);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setId(null);
  };

  const columns: GridColDef[] = [
    {
      field: 'exercicioOrcamentario',
      headerName: 'EXERCÍCIO ORÇAMENTÁRIO',
      flex: 1,
      minWidth: 250,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params) => <>{params.row.exercicioOrcamentario}</>,
    },

    {
      field: '_count',
      headerName: 'EIXOS FINANCIADOS',
      flex: 1,
      headerAlign: 'center',
      minWidth: 150,
      align: 'center',
      renderCell: (params) => (
        <>{params.row.dadosEixosFinanciados ? params.row.dadosEixosFinanciados._count.id : 0}</>
      ),
    },

    {
      field: 'eixoFinanciado',
      headerName: 'VALOR CUSTEIO',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params) => (
        <>
          {' '}
          R${' '}
          {tratandoValorCentavos(
            params.row.dadosEixosFinanciados
              ? params.row.dadosEixosFinanciados._sum.valorInicialCusteio
              : 0
          )}
        </>
      ),
    },
    {
      field: 'valorInicialInvestimento',
      headerName: 'VALOR INVESTIMENTO',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',

      renderCell: (params) => (
        <>
          R${' '}
          {tratandoValorCentavos(
            params.row.dadosEixosFinanciados
              ? params.row.dadosEixosFinanciados._sum.valorInicialInvestimento
              : 0
          )}
        </>
      ),
    },
    {
      field: 'valorTotal',
      headerName: 'VALOR TOTAL',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <>
          R${' '}
          {params.row.dadosEixosFinanciados &&
            tratandoValorCentavos(
              params.row.dadosEixosFinanciados._sum.valorInicialInvestimento +
                params.row.dadosEixosFinanciados._sum.valorInicialCusteio
            )}
          {!params.row.dadosEixosFinanciados && tratandoValorCentavos(0)}
        </>
      ),
    },

    {
      field: 'prazoExecucao',
      headerName: 'PRAZO DE EXECUÇÃO',
      flex: 1,
      headerAlign: 'center',
      minWidth: 150,

      align: 'center',
      renderCell: (params) => tratandoData(params.row.prazoExecucao),
    },
    {
      field: 'status',
      headerName: 'SITUAÇÃO',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,

      renderCell: (params) => (
        <Chip
          label={params.row.status}
          sx={{ color: 'white', fontWeight: '600' }}
          color={params.row.status === 'Pendente' ? 'warning' : 'success'}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      renderCell: (params) => (
        <Box>
          <IconButton color="primary" href={`plano/${params.row.id}`}>
            <VisibilityIcon />
          </IconButton>

          <IconButton
            disabled={params.row.status !== 'Pendente'}
            color="warning"
            onClick={() => handleButtonClick(params.row.id, 'Editar')}
          >
            <ModeEditIcon />
          </IconButton>
        </Box>
      ),
    },
  ];
  const inputSearch = () => {
    if (checked) {
      setSearch('');
    }
    setChecked((prev: boolean) => !prev);
  };
  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={2}>
        <CustomBreadcrumbs
          heading="Fundo Nacional de Segurança Pública"
          links={[
            { name: 'Planejamento', href: '/planejamento' },
            { name: 'Fundo Nacional', href: '/planejamento/fundonacional' },
            { name: 'Plano de Aplicação', href: '/planejamento/fundonacional/planodeaplicacao' },
          ]}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} sx={{ my: 2 }}>
            <Stack direction="row" spacing={2}>
              <TextField
                size="small"
                fullWidth
                onChange={(event) => setSearch(event.target.value)}
              />
              <IconButton
                color="warning"
                sx={{ background: '#FADCB2', mx: 1 }}
                onClick={inputSearch}
              >
                <SearchIcon />
              </IconButton>
            </Stack>
          </Grid>

          <Grid item xs={12} md={9} sx={{ textAlign: 'right', my: 2 }}>
            <Button
              href="/planejamento/fundonacional/planodeaplicacao/cadastro"
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
            >
              Novo Plano
            </Button>
          </Grid>
          <Grid xs={12} mt={2}>
            <CustomContainerList title="Plano de Aplicação do Fundo Nacional de Segurança Pública">
              {rows && (
                <TableContainer
                  columns={columns}
                  rows={rows.results}
                  rowCount={rows.total}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                />
              )}
            </CustomContainerList>
          </Grid>
        </Grid>
      </Stack>

      {id && isModalOpen && modalType === 'Editar' && (
        <AtualizarPlano
          open={isModalOpen}
          handleClose={handleCloseModal}
          id={id}
          RefecthPlano={RefecthPlano}
        />
      )}
    </Box>
  );
}
