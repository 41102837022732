import * as yup from 'yup';

export interface DataMetaGeral {
  id?: string;
  nome: string;
  planoAplicacaoId: string;
  eixoFinanciadoId: string;
  usuarioUpdateId?: string;
  atualizadoEm?: Date;
}

export const schemaMetaGeral = yup.object({
  nome: yup.string().required('Meta Geral é Obrigatório'),
  planoAplicacaoId: yup.string().required('Exercicio Orçamentário é Obrigatório'),
  eixoFinanciadoId: yup.string().required('Eixo é Obrigatório'),
  usuarioUpdateId: yup.string().optional(),
  atualizadoEm: yup.date().optional(),
});
export const defaultValuesMetaGeral = {
  nome: '',
  planoAplicacaoId: '',
  eixoFinanciadoId: '',
  usuarioUpdateId: '',
  atualizadoEm: new Date(),
};
