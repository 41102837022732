import { useState } from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { Box, Button, Chip, Grid, IconButton, MenuItem, TextField } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {
  ExcluirRendimento,
  getRendimentos,
  getRendimentosPlano,
} from 'src/services/Planejamento/FundoNacional/rendimento';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import TableContainer from 'src/components/Table';
import { tratandoData, tratandoValorCentavos } from 'src/config/utils';
import ModalExcluir from 'src/components/Modal/Excluir';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import { getPlanoAplicacaoId } from 'src/services/Planejamento/FundoNacional/planoAplicacao';
import CadastrarRendimento from '../../Rendimentos/Cadastro/Rendimento';

export default function PlanoAplicacaoRendimentoVisualizar({ id }: { id: string }) {
  const include: string[] = ['conta'];
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 7,
  });
  const skip = paginationModel.page * paginationModel.pageSize;
  const take = paginationModel.pageSize;
  const [search, setSearch] = useState<string>('');
  const [conta, setConta] = useState<string>('');
  const [idRendimento, setId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setmodalType] = useState<string>();

  const { mutate } = useMutation({
    mutationFn: ExcluirRendimento,
    onSuccess: () => {
      RefecthRendimentos();
      ToastFun('Excluido com Sucesso', 'success');
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  const { data: getRendimentosPlanoID, isPending } = useQuery({
    queryKey: ['getRendimentosPlano', id],
    queryFn: getRendimentosPlano,
  });
  const { data: rows, refetch: RefecthRendimentos } = useQuery({
    queryKey: ['getRendimentos', skip, take, search, include, conta, id],
    queryFn: getRendimentos,
  });
  const { data: plano } = useQuery({
    queryKey: ['getPlanoAplicacao', id],
    queryFn: getPlanoAplicacaoId,
  });
  const handleButtonClick = (idPlano: string, type: string) => {
    setId(idPlano);
    RefecthRendimentos();
    setIsModalOpen(true);
    setmodalType(type);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  if (isPending) {
    return <ModalCarregamento isOpen={isPending} />;
  }

  const columns: GridColDef[] = [
    {
      field: 'conta',
      headerName: 'CONTA',
      flex: 1,
      minWidth: 100,
    },

    {
      field: 'valor',
      headerName: 'VALOR',
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <>R$ {tratandoValorCentavos(params.row.valor)}</>,
    },
    {
      field: 'dataReferencia',
      headerName: 'DATA DE REFERÊNCIA',
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <> {tratandoData(params.row.dataReferencia)}</>,
    },
    {
      field: 'criadoEm',
      headerName: 'CRIADO EM ',
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <> {tratandoData(params.row.criadoEm)}</>,
    },
    {
      field: 'status',
      headerName: 'SITUAÇÃO',
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          sx={{ color: 'white' }}
          color={params.row.status === 'Pendente' ? 'warning' : 'success'}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,

      renderCell: (params) => (
        <Box>
          <IconButton
            color="error"
            aria-label="delete"
            disabled={params.row.status === 'Autorizado'}
            onClick={() => handleButtonClick(params.row.id, 'Excluir')}
          >
            <DeleteIcon />
          </IconButton>
        </Box>
      ),
    },
  ];
  return (
    <Box
      sx={{
        p: 1,

        margin: 'auto',
        mt: 3,
      }}
    >
      <CustomContainerList type="detalhes" title="Rendimentos">
        <Grid container>
          <Grid item md={3} xs={12}>
            <Box sx={{ my: 2 }}>
              <TextField
                fullWidth
                select
                label="Conta"
                name="conta"
                size="small"
                defaultValue="todos"
                onChange={(e) =>
                  e.target.value !== 'todos' ? setConta(e.target.value) : setConta('')
                }
              >
                {getRendimentosPlanoID?.results.map((item: any) => (
                  <MenuItem key={item.id} value={item.conta}>
                    {item.conta}
                  </MenuItem>
                ))}
                <MenuItem value="todos" selected>
                  Todas as contas
                </MenuItem>
              </TextField>
            </Box>
          </Grid>
          <Grid item xs={12} md={9} sx={{ textAlign: 'right', my: 2 }}>
            <Button
              variant="contained"
              color="primary"
              disabled={plano?.planoAplicacao?.status === 'Pendente'}
              onClick={() => handleButtonClick('', 'Cadastro')}
              startIcon={<AddIcon />}
            >
              Rendimento
            </Button>
          </Grid>
          <Grid item md={12} xs={12}>
            {rows && (
              <TableContainer
                columns={columns}
                rows={rows.results}
                rowCount={rows.total}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
              />
            )}
          </Grid>
        </Grid>
      </CustomContainerList>
      {isModalOpen && modalType === 'Cadastro' && (
        <CadastrarRendimento
          open={isModalOpen}
          handleClose={handleCloseModal}
          RefecthRendimentos={RefecthRendimentos}
          planoAplicacao={id}
        />
      )}
      {idRendimento && isModalOpen && modalType === 'Excluir' && (
        <ModalExcluir
          isOpen={isModalOpen}
          nomeAcao="Rendimento"
          id={idRendimento}
          mutate={mutate}
          handleClose={handleCloseModal}
        />
      )}
    </Box>
  );
}
