import { Alert, Box, Button, Grid, MenuItem, TextField, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { useWatch } from 'react-hook-form';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import SaveIcon from '@mui/icons-material/Save';
import { getPlanoAplicacao } from 'src/services/Planejamento/FundoNacional/planoAplicacao';
import { getEixosFinanciados } from 'src/services/Planejamento/FundoNacional/eixoFinanciado';
import { getMetasFisicas } from 'src/services/Planejamento/FundoNacional/metas';
import { getRendimentoConta } from 'src/services/Planejamento/FundoNacional/rendimento';
import TextFieldMoney from 'src/components/Forms/TextFieldMoney';
import { tratandoValorCentavos } from 'src/config/utils';

export default function DestinacaoRendimentoForms({ setValue }: { setValue: any }) {
  const [instituicoesAlocacao, setInstituicoesAlocacao] = useState([]);
  const { data: planoAplicacao } = useQuery({
    queryKey: ['getPlanoAplicacao'],
    queryFn: getPlanoAplicacao,
  });

  const planoAplicacaoId = useWatch({ name: 'planoAplicacaoId' });
  const eixoFinanciadoId = useWatch({ name: 'eixoFinanciadoId' });

  const natureza = useWatch({ name: 'natureza' });
  const conta = useWatch({ name: 'conta' });

  const { data: eixosFinanciados, refetch: RefecthEixos } = useQuery({
    queryKey: ['getEixosFinanciados', planoAplicacaoId],
    queryFn: getEixosFinanciados,
    enabled: !!planoAplicacaoId,
  });

  const { data: rendimentoConta } = useQuery({
    queryKey: ['getRendimentoConta', eixoFinanciadoId, conta],
    queryFn: getRendimentoConta,
    enabled: !!eixoFinanciadoId && !!conta,
  });

  const include = ['metaInstituicaoCount', 'metaGeral', 'metasInstituicoes'];

  const buscarMetas = {
    include,
    planoAplicacaoId,
    eixoFinanciadoId,
    natureza,
  };

  // BUSCAR METAS FISICA
  const { data: metasFisicas } = useQuery<{ total: number; results: object[] }>({
    queryKey: ['getMetaFisica', planoAplicacaoId, eixoFinanciadoId, natureza],
    queryFn: () => getMetasFisicas(buscarMetas),
    enabled: !!planoAplicacaoId && !!eixoFinanciadoId && !!natureza,
  });
  const metaFisicaId = useWatch({ name: 'metaFisicaId' });

  useEffect(() => {
    if (rendimentoConta) {
      setValue('rendimentoContaId', rendimentoConta.id);
    }
  }, [rendimentoConta, setValue]);

  useEffect(() => {
    if (metaFisicaId) {
      const metaAlocacao: any = metasFisicas?.results.filter(
        (item: any) => item.id === metaFisicaId
      );
      if (metaAlocacao && metaAlocacao.length > 0) {
        setInstituicoesAlocacao(metaAlocacao[0].metasInstituicoes);
      } else {
        setInstituicoesAlocacao([]);
      }
    }
  }, [metaFisicaId, metasFisicas?.results]);

  useEffect(() => {
    if (eixoFinanciadoId && natureza) {
      const palavraFormatada = natureza.charAt(0).toUpperCase() + natureza.slice(1).toLowerCase();

      const propertyName = `conta${palavraFormatada}`;
      const eixoSelecioando = eixosFinanciados?.find(
        (element: any) => eixoFinanciadoId === element.id
      );
      if (eixoSelecioando) {
        setValue('conta', eixoSelecioando[propertyName]);
      }
    }
  }, [eixoFinanciadoId, eixosFinanciados, natureza, setValue]);

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12} lg={3}>
        <TextFieldRG select name="planoAplicacaoId" label="Exercício Orçamentário">
          {planoAplicacao?.results.map((item: any, indexExercicio: number) => (
            <MenuItem key={item} value={item.id} disabled={item.status === 'Pendente'}>
              {item.exercicioOrcamentario}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={3}>
        <TextFieldRG select name="natureza" label="Natureza">
          <MenuItem value="investimento">Investimento</MenuItem>
          <MenuItem value="custeio">Custeio</MenuItem>
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <TextFieldRG select name="eixoFinanciadoId" label="Eixo/Área Temática">
          {eixosFinanciados?.map((item: any, indexEixoFinanciado: number) => (
            <MenuItem key={indexEixoFinanciado} value={item.id}>
              {item.eixoNome}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>

      <Grid item xs={12} md={12} lg={8}>
        <TextFieldRG select name="metaFisicaId" label="Meta Física">
          {metasFisicas?.results.map((item: any) => (
            <MenuItem key={item.id} value={item.id}>
              {item.nome}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <TextFieldRG select name="metaInstituicaoId" label="Instituição">
          {instituicoesAlocacao.map((item: any) => (
            <MenuItem key={item.id} value={item.id}>
              {item.sigla}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <Typography variant="subtitle2">Conta</Typography>
        <TextFieldRG placeholder="Conta" label="" name="conta" disabled />
      </Grid>

      <Grid item xs={12} md={12} lg={4}>
        <Typography variant="subtitle2">Valor Disponível</Typography>

        <TextField
          fullWidth
          sx={{ my: 2 }}
          label=""
          value={`R$ ${tratandoValorCentavos(
            rendimentoConta ? rendimentoConta.valorDisponivel : 0
          )}`}
          disabled
        />
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <Typography variant="subtitle2">Valor Alocado</Typography>
        <TextFieldMoney label="" name="valor" />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <TextFieldRG label="Processo SEI" name="processoSei" />
      </Grid>
      <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
        <Button variant="contained" color="success" type="submit" startIcon={<SaveIcon />}>
          Cadastrar
        </Button>
      </Grid>
    </Grid>
  );
}
