import { useContext } from 'react';
import { Container, Grid, Box, Typography, Stack, LinearProgress } from '@mui/material';
import MinervaVertical from 'src/assets/images/Minerva_vertical.png';
import { AuthContext } from 'src/Context/ContextUser';
import { useNavigate } from 'react-router-dom';
import { ToastFun } from 'src/config/functions';
import './index.css';
import { useForm } from 'react-hook-form';
import 'react-toastify/dist/ReactToastify.css';
import FormsProvider from 'src/components/Forms/Provider';
import { useMutation } from '@tanstack/react-query';
import { yupResolver } from '@hookform/resolvers/yup';
import { AuthLogin } from 'src/services/Admin/post';
import LoginForm from './Login';
import { ErroResponse } from '../types';
import { defaultValuesPlano, schemaLogin } from './types';

export default function Login() {
  const { loginAuth } = useContext(AuthContext);
  const methods = useForm({
    resolver: yupResolver(schemaLogin),
    defaultValues: defaultValuesPlano,
  });

  const navigate = useNavigate();

  const { mutate, isPending } = useMutation({
    mutationFn: AuthLogin,
    onSuccess: (data) => {
      const dados = { ...data.usuario, token: data.token, permissoes: data.permissoes };
      loginAuth(dados);
      navigate('/menu');
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  const onSubmit = async (dadosLogin: any) => {
    mutate(dadosLogin);
  };

  return (
    <div className="Login">
      <Container sx={{ textAlign: 'center', p: 5 }}>
        <Grid
          container
          justifyContent="center"
          sx={{
            width: {
              md: '40%',
              sm: '100%',
            },
            mt: 8,
            mx: 'auto',
          }}
        >
          <Grid item md={8} xs={10}>
            <Box my={1}>
              <img src={MinervaVertical} alt="" width={250} />
            </Box>
          </Grid>
          <Grid item md={8} xs={10} mt={2}>
            <FormsProvider methods={methods} handleSubmit={onSubmit}>
              <LoginForm />
            </FormsProvider>
          </Grid>
          <Grid item md={8} xs={10} mt={2}>
            {isPending && (
              <Stack sx={{ width: '100%', color: 'grey.500' }} spacing={2}>
                <LinearProgress color="success" />
              </Stack>
            )}
          </Grid>

          <Box sx={{ mx: 'auto', textAlign: 'center', my: 2 }}>
            <Typography sx={{ color: '#c4c4c4', fontSize: '12px', p: 3 }}>
              SECRETARIA DE JUSTIÇA E SEGURANÇA PÚBLICA
            </Typography>
          </Box>
        </Grid>
      </Container>
    </div>
  );
}
