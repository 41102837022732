import Endpoints from 'src/services/endpoints';
import { Api } from 'src/services/Api';
import { DataMetaGeral } from 'src/pages/Planejamento/FundoNacional/Metas/Cadastro/MetaGeral/type';
import { DataMetasFisicas } from 'src/pages/Planejamento/FundoNacional/Metas/Cadastro/MetaFisica/types';

// GET
export async function getMetasGerais({
  skip,
  take,
  filters,
  include,
  planoAplicacaoId,
  eixoFinanciadoId,
}: {
  skip?: number;
  take?: number;
  filters?: {};
  include?: string[];
  planoAplicacaoId?: string;
  eixoFinanciadoId?: string;
}) {
  const response = await Api.get(Endpoints.metaGeral, {
    params: {
      skip,
      take,
      filters,
      include,
      planoAplicacaoId,
      eixoFinanciadoId,
    },
  });
  return response.data;
}

export async function getMetasFisicas({
  skip,
  take,
  filters,
  include,
  planoAplicacaoId,
  eixoFinanciadoId,
  natureza,
  instituicaoId,
}: {
  skip?: number;
  take?: number;
  filters?: {};
  include?: string[];
  planoAplicacaoId?: string;
  eixoFinanciadoId?: string;
  natureza?: string;
  instituicaoId?: string;
}) {
  const response = await Api.get(Endpoints.metaFisica, {
    params: {
      skip,
      take,
      filters,
      include,
      planoAplicacaoId,
      eixoFinanciadoId,
      natureza,
      instituicaoId,
    },
  });
  return response.data;
}
export async function getMetaInstituicao({ queryKey }: { queryKey: any }) {
  const [_key, idMetaInstituicao] = queryKey;
  const response = await Api.get(`${Endpoints.metaInstituicao}/${idMetaInstituicao}`);
  return response.data;
}

export async function getMetasFisicasRemanejamento({ queryKey }: { queryKey: any }) {
  const [_key, idExercicio, idEixoFinancido, natureza] = queryKey;

  const response = await Api.get(Endpoints.metaFisicaRemanejamento, {
    params: {
      plano: idExercicio,
      eixoFinanciado: idEixoFinancido,
      natureza,
    },
  });
  return response.data;
}

export async function getMetaGeral({ queryKey }: { queryKey: any }) {
  const [_key, id, include] = queryKey;
  const response = await Api.get(`${Endpoints.metaGeral}/${id}`, {
    params: { include },
  });

  return response.data;
}

export async function getMetaFisica(id: string, include?: string[]) {
  const response = await Api.get(`${Endpoints.metaFisica}/${id}`, {
    params: {
      include,
    },
  });

  return response.data;
}
// POST
export async function postCadastrarMetaGeral(dadosMetas: DataMetaGeral) {
  const result = await Api.post(Endpoints.metaGeral, dadosMetas);

  return result;
}

export async function postCadastrarMetasFisicas(dadosMetas: DataMetasFisicas) {
  const result = await Api.post(Endpoints.metaFisica, dadosMetas);

  return result;
}
// PATCH
export async function AutorizarMeta({
  id,
  usuarioAuthorizationId,
}: {
  id: string;
  usuarioAuthorizationId: string;
}) {
  const response = await Api.patch(`${Endpoints.metaGeral}/autorizar/${id}`, {
    usuarioAuthorizationId,
  });
  return response.data;
}
export async function AtualizarMetaGeralID(dadosPlano: DataMetaGeral) {
  const response = await Api.patch(`${Endpoints.metaGeral}/${dadosPlano.id}`, { ...dadosPlano });
  return response.data;
}

// DELETE
export async function ExcluirMetaGeral(dados: any) {
  const response = await Api.delete(`${Endpoints.metaGeral}/${dados}`);
  return response.data;
}
export async function ExcluirMetaFisica(dados: any) {
  const response = await Api.delete(`${Endpoints.metaFisica}/${dados}`);
  return response.data;
}
