export interface User {
  token: string;
  email: string;
  id: string;
  nome: string;
  status: string;
  permissoes: string[];
}
// CONTEXT
export interface AuthContextProps {
  state: AuthState; // TRAZENDO DADOS DO USUÁRIO
  loginAuth: (UserData: any) => void;
  logout: () => void;
  openSidebar: () => void; // Função para abrir a sidebar
  closeSidebar: () => void; // Função para fechar a sidebar
}

// REDUCER
export interface AuthState {
  isLoading: boolean;
  isAuth: boolean;
  usuario: User | null;
  sidebarOpen: boolean;
}

export interface AuthAction {
  type: 'Login' | 'Logout' | 'SidebarOpen' | 'SidebarClose';
  payload?: {
    usuario?: User;
  };
}
export const initialState: AuthState = {
  isAuth: false,
  usuario: null,
  isLoading: true,
  sidebarOpen: false, // Estado inicial da sidebar
};
