import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { useMutation, useQuery } from '@tanstack/react-query';
import SubdirectoryArrowLeftIcon from '@mui/icons-material/SubdirectoryArrowLeft';
import { useNavigate, useParams } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ErroResponse } from 'src/pages/types';
import { ToastFun } from 'src/config/functions';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import { tratandoData, tratandoValorCentavos } from 'src/config/utils';
import { AuthContext } from 'src/Context/ContextUser';
import { useContext, useState } from 'react';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import {
  AutorizarPlano,
  getPlanoAplicacaoId,
} from 'src/services/Planejamento/FundoNacional/planoAplicacao';
import { getEixoFinanciadoMetas } from 'src/services/Planejamento/FundoNacional/eixoFinanciado';
import ModalAutorizar from 'src/components/Modal/Autorizar';

const TypographyTitulo = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 700,
  color: '#555555',
  textTransform: 'uppercase',
}));
const TypographyBody = styled(Typography)(({ theme }) => ({
  fontSize: '13px',
  color: '#555555',
  textTransform: 'uppercase',
}));

export default function PlanoAplicacaoVisualizarAutorizacao() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const nav = useNavigate();
  const { id } = useParams();
  const { state } = useContext(AuthContext);
  const dadosAutorizacao = {
    id,
    usuarioAuthorizationId: state.usuario?.id,
  };
  const include = ['eixoFinanciado', 'user'];
  const {
    data: rowsPlano,
    refetch,
    isPending,
  } = useQuery({
    queryKey: ['getPlanoAplicacao', id, include],
    queryFn: getPlanoAplicacaoId,
  });
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const { mutate } = useMutation({
    mutationFn: AutorizarPlano,
    onSuccess: (data) => {
      refetch();

      ToastFun('Plano Autorizado com sucesso', 'success');
      nav('/planejamento/fundonacional/autorizacao');
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });
  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  if (isPending || !rowsPlano) return <ModalCarregamento isOpen={isPending} />;

  return (
    <Box
      sx={{
        m: 'auto',
      }}
    >
      <CustomBreadcrumbs
        heading="Enfrentamento à Criminalidade Violenta"
        links={[
          { name: 'Planejamento', href: '/planejamento' },
          { name: 'Fundo Nacional', href: '/planejamento/fundonacional' },
          { name: 'Autorização', href: '/planejamento/fundonacional/autorizacao' },
          { name: 'Autorizar', href: '#' },
        ]}
      />

      <Stack
        spacing={3}
        sx={{
          mt: '2rem',
        }}
      >
        <CustomContainerList key={rowsPlano} title="Plano de Aplicação - Autorização">
          <>
            <Box
              sx={{
                display: {
                  md: 'block',
                  sm: 'none',
                  xs: 'none',
                },
              }}
            >
              <Grid container p={2} spacing={1} justifyContent="right">
                <Grid item md={4} xs={12} my={2}>
                  <Stack spacing={2}>
                    <TypographyTitulo>Exercício Orçamentário</TypographyTitulo>
                    <TypographyBody>
                      {rowsPlano.planoAplicacao.exercicioOrcamentario}
                    </TypographyBody>
                  </Stack>
                </Grid>
                <Grid item md={4} xs={12} my={2} sx={{ textAlign: 'center' }}>
                  <Stack spacing={2}>
                    <TypographyTitulo> Prazo para Execução</TypographyTitulo>
                    <TypographyBody>
                      {tratandoData(rowsPlano.planoAplicacao.prazoExecucao)}
                    </TypographyBody>
                  </Stack>
                </Grid>
                <Grid item md={4} xs={12} my={2} sx={{ textAlign: 'center' }}>
                  <Stack spacing={2}>
                    <TypographyTitulo> Situação</TypographyTitulo>
                    <TypographyBody>
                      <Chip
                        label={rowsPlano.planoAplicacao.status}
                        color={
                          rowsPlano.planoAplicacao.status === 'Pendente' ? 'warning' : 'success'
                        }
                      />
                    </TypographyBody>
                  </Stack>
                </Grid>

                <Grid item md={12} xs={12} my={2}>
                  <Divider sx={{ my: 1 }} />
                  <Grid container>
                    <Grid md={4} xs={12} my={1}>
                      <TypographyTitulo>Eixo Financiado</TypographyTitulo>
                    </Grid>
                    <Grid md={2} xs={12} my={1} sx={{ textAlign: 'center' }}>
                      <TypographyTitulo>Conta Investimento</TypographyTitulo>
                    </Grid>
                    <Grid md={2} xs={12} my={1} sx={{ textAlign: 'center' }}>
                      <TypographyTitulo>Valor Investimento</TypographyTitulo>
                    </Grid>
                    <Grid md={1.5} xs={12} my={1} sx={{ textAlign: 'center' }}>
                      <TypographyTitulo>Conta Custeio</TypographyTitulo>
                    </Grid>
                    <Grid md={2.5} xs={12} my={1} sx={{ textAlign: 'center' }}>
                      <Stack spacing={2}>
                        <TypographyTitulo>Valor Custeio</TypographyTitulo>
                      </Stack>
                    </Grid>
                    <Grid md={12} xs={12} my={1}>
                      {rowsPlano.eixosFinanciados.map((eixo: any, index: number) => (
                        <Accordion key={eixo} sx={{ boxShadow: 0 }}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Grid key={eixo} container>
                              <Grid md={4} xs={12}>
                                <TypographyBody>
                                  <span style={{ fontWeight: '700', margin: 5 }}>{index + 1}</span>.{' '}
                                  {eixo.eixoNome}
                                </TypographyBody>
                              </Grid>
                              <Grid md={2} xs={12} sx={{ textAlign: 'center' }}>
                                <TypographyBody>{eixo.contaInvestimento}</TypographyBody>
                              </Grid>
                              <Grid md={2.3} xs={12} sx={{ textAlign: 'center' }}>
                                <TypographyBody>
                                  R$ {tratandoValorCentavos(eixo.valorInicialInvestimento)}
                                </TypographyBody>
                              </Grid>
                              <Grid md={1.5} xs={12} sx={{ textAlign: 'center' }}>
                                <TypographyBody>{eixo.contaCusteio}</TypographyBody>
                              </Grid>
                              <Grid md={2.1} xs={12} sx={{ textAlign: 'center', pl: 5 }}>
                                <TypographyBody>
                                  R$ {tratandoValorCentavos(eixo.valorInicialCusteio)}
                                </TypographyBody>
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                          <Metas idEixo={eixo.id} index={index} />
                        </Accordion>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={1.5} md={2}>
                  <Button
                    fullWidth
                    href="/planejamento/fundonacional/autorizacao/"
                    variant="contained"
                    color="info"
                    startIcon={<SubdirectoryArrowLeftIcon />}
                  >
                    Voltar
                  </Button>
                </Grid>
                {id && (
                  <Grid item lg={1.5} md={2}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="success"
                      startIcon={<TaskAltIcon />}
                      onClick={() => handleButtonClick()}
                    >
                      Autorizar
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Box>
            <Box
              sx={{
                display: {
                  md: 'none',
                  sm: 'block',
                  xs: 'block',
                },
              }}
            >
              <Alert severity="warning">
                É NECESSÁRIO ESTÁ NO COMPUTADOR PARA VISUALIZAR E AUTORIZAR O PLANO!
              </Alert>
              <Button
                variant="contained"
                color="info"
                sx={{ m: 2 }}
                href="/planejamento/fundonacional/autorizacao/"
                startIcon={<SubdirectoryArrowLeftIcon />}
              >
                Voltar
              </Button>
            </Box>
          </>
        </CustomContainerList>
      </Stack>
      {id && (
        <ModalAutorizar
          isOpen={isModalOpen}
          nomeAcao="Plano de Aplicação"
          id={id}
          mutate={mutate}
          handleClose={handleCloseModal}
        />
      )}
    </Box>
  );
}

function Metas({ idEixo, index }: { idEixo: string; index: number }) {
  const includeEixo = ['metasGerais', 'metasFisicas', 'metasInstituicoes'];
  const { data: rowsEixoFinanciadoMeta } = useQuery({
    queryKey: ['getPlanoAplicacao', idEixo, includeEixo],
    queryFn: getEixoFinanciadoMetas,
    enabled: !!idEixo,
  });
  return (
    <AccordionDetails>
      <Grid container>
        <Grid md={12} xs={12} my={1} p={2}>
          <TypographyTitulo>Metas Gerais</TypographyTitulo>
          {rowsEixoFinanciadoMeta?.metasGerais?.map((metaGeral: any, indexMeta: number) => (
            <Box key={metaGeral} my={2}>
              <Accordion sx={{ boxShadow: 0 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  sx={{ fontSize: '13px', textTransform: 'uppercase' }}
                >
                  {index + 1}.{indexMeta + 1}. {metaGeral.nome}
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container px={1} spacing={2}>
                    <Grid item md={8}>
                      <TypographyTitulo>Meta Física</TypographyTitulo>
                    </Grid>
                    <Grid item md={2}>
                      <TypographyTitulo>Natureza</TypographyTitulo>
                    </Grid>
                    <Grid item md={2}>
                      <TypographyTitulo>Despesa</TypographyTitulo>
                    </Grid>
                    <Grid item md={12}>
                      {metaGeral.metasFisicas.map((metaFisica: any, indexMetaFisica: number) => (
                        <Accordion key={metaFisica} sx={{ boxShadow: 0, p: 0 }}>
                          <AccordionSummary sx={{ p: 0 }} expandIcon={<ExpandMoreIcon />}>
                            <Grid container>
                              <Grid item md={8.2}>
                                <Box>
                                  <TypographyBody>
                                    <span
                                      style={{
                                        fontWeight: '700',
                                        margin: 5,
                                      }}
                                    >
                                      {index + 1}.{indexMeta + 1}.{indexMetaFisica + 1}
                                    </span>
                                    {metaFisica.nome}
                                  </TypographyBody>
                                </Box>
                              </Grid>
                              <Grid item md={2}>
                                <TypographyBody>{metaFisica.natureza}</TypographyBody>
                              </Grid>
                              <Grid item md={1.8}>
                                <TypographyBody>{metaFisica.nomeDespesa}</TypographyBody>
                              </Grid>
                            </Grid>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Grid container px={2} spacing={2}>
                              <Grid item md={8.1}>
                                <TypographyTitulo>Instituição Beneficiada</TypographyTitulo>
                              </Grid>
                              <Grid item md={2}>
                                <TypographyTitulo>Valor Inicial</TypographyTitulo>
                              </Grid>
                              <Grid item md={1.9}>
                                <TypographyTitulo>Unidade</TypographyTitulo>
                              </Grid>

                              {metaFisica.metasInstituicoes.map(
                                (
                                  metaFisicaInstituicao: any,
                                  indexMetaFisicaInstituicao: number
                                ) => (
                                  <>
                                    <Grid item md={8.1}>
                                      <TypographyBody>{metaFisicaInstituicao.nome}</TypographyBody>
                                    </Grid>
                                    <Grid item md={2}>
                                      <TypographyBody>
                                        R$
                                        {tratandoValorCentavos(metaFisicaInstituicao.valorInicial)}
                                      </TypographyBody>
                                    </Grid>
                                    <Grid item md={1.9}>
                                      <TypographyBody>
                                        {metaFisicaInstituicao.unidade?.sigla || 'Não se Aplica'}
                                      </TypographyBody>
                                    </Grid>
                                  </>
                                )
                              )}
                            </Grid>
                          </AccordionDetails>
                        </Accordion>
                      ))}
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Box>
          ))}
        </Grid>
      </Grid>
    </AccordionDetails>
  );
}
