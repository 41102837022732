import { Grid, Button, MenuItem, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import { useQuery } from '@tanstack/react-query';

import { NumericFormat } from 'react-number-format';
import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { getPlanoAplicacao } from 'src/services/Planejamento/FundoNacional/planoAplicacao';
import { getEixosFinanciados } from 'src/services/Planejamento/FundoNacional/eixoFinanciado';
import TextFieldMoney from 'src/components/Forms/TextFieldMoney';

export default function FormularioRendimento({ setValue }: { setValue: any }) {
  const [conta, setConta] = useState();

  const { data: planoAplicacao } = useQuery({
    queryKey: ['getPlanoAplicacao'],
    queryFn: getPlanoAplicacao,
  });

  const planoAplicacaoId = useWatch({ name: 'planoAplicacaoId' });
  const eixoFinanciadoId = useWatch({ name: 'eixoFinanciadoId' });
  const natureza = useWatch({ name: 'natureza' });

  const { data: eixosFinanciados } = useQuery({
    queryKey: ['getEixosFinanciados', planoAplicacaoId],
    queryFn: getEixosFinanciados,
    enabled: !!planoAplicacaoId,
  });

  useEffect(() => {
    if (planoAplicacaoId && planoAplicacaoId && natureza) {
      if (eixoFinanciadoId && eixoFinanciadoId && natureza) {
        eixosFinanciados?.forEach((element: any) => {
          const propertyName = `conta${natureza}`;

          if (element[propertyName] !== undefined && eixoFinanciadoId === element.id) {
            setConta(element[propertyName]);
            setValue('conta', element[propertyName]);
          }
        });
      }
    }
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12} lg={3}>
        <TextFieldRG select name="planoAplicacaoId" label="Exercício Orçamentário" disabled>
          {planoAplicacao?.results.map((item: any, indexExercicio: number) => (
            <MenuItem key={indexExercicio} value={item.id} disabled={item.status === 'Pendente'}>
              {item.exercicioOrcamentario}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>

      <Grid item xs={12} md={12} lg={6}>
        <TextFieldRG select name="eixoFinanciadoId" label="Eixo/Área Temática">
          {eixosFinanciados?.map((item: any, indexEixoFinanciado: number) => (
            <MenuItem key={indexEixoFinanciado} value={item.id}>
              {item.eixoNome}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>

      <Grid item xs={12} md={12} lg={3}>
        <TextFieldRG sx={{ my: 2 }} select name="natureza" fullWidth label="Natureza">
          <MenuItem value="Investimento">Investimento</MenuItem>
          <MenuItem value="Custeio">Custeio</MenuItem>
        </TextFieldRG>
      </Grid>

      <Grid item xs={12} md={12} lg={3}>
        <TextFieldRG placeholder="Conta" label="" value={conta} name="conta" disabled />
      </Grid>
      <Grid item xs={12} md={12} lg={3}>
        <TextFieldRG
          placeholder=""
          type="date"
          label="Data de Referência"
          focused
          name="dataReferencia"
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <TextFieldMoney label="Valor" name="valor" />
      </Grid>

      <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
        <Button variant="contained" color="success" type="submit" startIcon={<SaveIcon />}>
          Cadastrar
        </Button>
      </Grid>
    </Grid>
  );
}
