import { tratandoValor } from 'src/config/utils';
import * as yup from 'yup';

export interface DadosRemanejamento {
  planoAplicacaoId: string;
  eixoFinanciadoId: string;
  natureza: string;
  metaFisicaRetiradaId: string;
  metaFisicaRecebidaId: string;
  instituicaoRetiradaId: string;
  instituicaoRecebidaId: string;
  processoSei: string;
  valorRemanejado: any;
}

export const schemaRemanejamento = yup.object({
  planoAplicacaoId: yup.string().required('Exercicio Orçamentário é Obrigatório'),
  eixoFinanciadoId: yup.string().required('Eixo é Obrigatório'),
  natureza: yup.string().required('Natureza é Obrigatório'),
  metaFisicaRetiradaId: yup.string().required('é Obrigatório'),
  metaFisicaRecebidaId: yup.string().required('é Obrigatório'),
  instituicaoRetiradaId: yup.string().required('é Obrigatório'),
  instituicaoRecebidaId: yup.string().required('é Obrigatório'),
  processoSei: yup.string().required('é Obrigatório'),
  valorRemanejado: yup.mixed().transform((value: any) => tratandoValor(value)),
});
