import { useEffect } from 'react';
import { Box, Grid, MenuItem, Typography } from '@mui/material';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { FieldValues, UseFormSetValue, useWatch } from 'react-hook-form';
import TextFieldMoney from 'src/components/Forms/TextFieldMoney';
import { EixoResponse, EixosFinanciados } from './type';

interface Eixos {
  index: number;
  setValue: UseFormSetValue<FieldValues>;
  memoizedEixosCad: EixoResponse | undefined;
  eixosList: EixosFinanciados[];
  excluirEixo: (index: number) => void;
}

export function EixoForms({ index, setValue, excluirEixo, memoizedEixosCad, eixosList }: Eixos) {
  const idEixoFinanciado = useWatch({ name: `eixosFinanciados[${index}].id` });
  const count = useWatch({ name: `eixosFinanciados[${index}].countMetasFisica` });

  const ValorCusteio = useWatch({ name: `eixosFinanciados[${index}].valorInicialCusteio` });
  const ValorInvestimento = useWatch({
    name: `eixosFinanciados[${index}].valorInicialInvestimento`,
  });

  useEffect(() => {
    if (ValorCusteio) {
      setValue(`eixosFinanciados[${index}].valorInicialCusteio`, ValorCusteio);
      setValue(`eixosFinanciados[${index}].saldoEstimativoCusteio`, ValorCusteio);
      setValue(`eixosFinanciados[${index}].saldoRealCusteio`, ValorCusteio);
    }
    if (ValorInvestimento) {
      setValue(`eixosFinanciados[${index}].valorInicialInvestimento`, ValorInvestimento);
      setValue(`eixosFinanciados[${index}].saldoEstimativoInvestimento`, ValorInvestimento);
      setValue(`eixosFinanciados[${index}].saldoRealInvestimento`, ValorInvestimento);
    }
  }, [ValorCusteio, ValorInvestimento, index, setValue]);
  return (
    <Grid container spacing={2} mb={5}>
      <Grid item xs={12} md={12}>
        <Box
          px={1}
          my={1}
          sx={{ background: '#eef7f1', borderRadius: '5px', px: 1, color: 'green' }}
        >
          <Grid container>
            <Grid item md={6} xs={10}>
              <Typography variant="subtitle2" sx={{ py: 1 }}>
                Eixo Financiado
              </Typography>
            </Grid>
            <Grid item md={6} xs={2} sx={{ textAlign: 'right', py: 0.5 }}>
              <IconButton
                disabled={!!idEixoFinanciado}
                color="error"
                size="small"
                onClick={() => excluirEixo(index)}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Grid>

      {/* SELECT EIXO */}
      <Grid item xs={12} md={12}>
        <Box display="grid">
          <TextFieldRG select name={`eixosFinanciados[${index}].eixoId`} label="Eixo">
            {memoizedEixosCad?.results.map((item: any) => (
              <MenuItem
                disabled={eixosList?.some((inst: any) => inst.eixoId === item.id)}
                key={item.id}
                value={item.id}
              >
                {item.nome}
              </MenuItem>
            ))}
          </TextFieldRG>
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography variant="subtitle2">Investimento</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextFieldRG
          type="text"
          name={`eixosFinanciados[${index}].agenciaInvestimento`}
          label="Agência"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextFieldRG
          type="text"
          name={`eixosFinanciados[${index}].contaInvestimento`}
          label="Conta"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextFieldMoney
          disabled={count}
          label="Valor Investimento"
          name={`eixosFinanciados[${index}].valorInicialInvestimento`}
        />
      </Grid>

      <Grid item xs={12} md={12}>
        <Typography variant="subtitle2">Custeio</Typography>
      </Grid>
      <Grid item xs={12} md={4}>
        <TextFieldRG
          type="text"
          name={`eixosFinanciados[${index}].agenciaCusteio`}
          label="Agência"
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextFieldRG type="text" name={`eixosFinanciados[${index}].contaCusteio`} label="Conta" />
      </Grid>
      <Grid item xs={12} md={4}>
        <TextFieldMoney
          disabled={count}
          label="Valor Custeio"
          name={`eixosFinanciados[${index}].valorInicialCusteio`}
        />
      </Grid>
      <Grid item md={12}>
        {count > 0 && (
          <Typography color="error" sx={{ fontSize: '13px' }}>
            Não será possível alterar o valor caso existam metas vinculadas a este eixo.
          </Typography>
        )}
      </Grid>
    </Grid>
  );
}
