import { tratandoValor } from 'src/config/utils';
import * as yup from 'yup';

export const schemaMetasFisicas = yup.object<DataMetasFisicas>({
  planoAplicacaoId: yup.string().required('Exercicio Orçamentário é Obrigatório'),
  eixoFinanciadoId: yup.string().required('Eixo é Obrigatório'),
  metaGeralId: yup.string().required('MetaGeral é Obrigatório'),
  usuarioCreateId: yup.string(),
  usuarioUpdateId: yup.string(),
  metasFisicas: yup
    .array()
    .of(
      yup.object().shape({
        nome: yup.string().required('Meta Física é Obrigatório'),
        natureza: yup.string().required('Natureza é Obrigatório'),
        tipoDespesa: yup.string().required('Despesa é Obrigatório'),
        metasInstituicoes: yup.array().of(
          yup.object().shape({
            instituicaoId: yup.string().required('Intituição é Obrigatório'),
            valorInicial: yup
              .mixed()
              .transform((value: any) => tratandoValor(value))
              .required('Valor é Obrigatório'),
            unidadeId: yup.string(),
            saldoReal: yup.mixed().transform((value: any) => tratandoValor(value)),
            saldoEstimativo: yup.mixed().transform((value: any) => tratandoValor(value)),
          })
        ),
      })
    )
    .required(),
});

export interface DataMetasFisicas {
  planoAplicacaoId: string;
  eixoFinanciadoId: string;
  metaGeralId: string;
  usuarioCreateId: string;
  usuarioUpdateId: string;
  metasFisicas: MetaFisica[];
}
export interface MetaFisica {
  nome: string;
  natureza: string;
  tipoDespesa: string;
  metasInstituicoes: MetaInstituicao[];
}

export interface MetaInstituicao {
  instituicaoId: string;
  valorInicial: any;
  unidade: string;
  saldoReal: any;
  saldoEstimativo: any;
}

export const defaultValuesMetasFisicas = {
  planoAplicacaoId: '',
  eixoFinanciadoId: '',
  metaGeralId: '',
  usuarioCreateId: '',
  usuarioUpdateId: '',
  metasFisicas: [
    { metasInstituicoes: [{ instituicaoId: '', valorInicial: undefined, unidade: '' }] },
  ],
};
