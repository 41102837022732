import { Grid, Button, MenuItem, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import { useQuery } from '@tanstack/react-query';
import { NumericFormat } from 'react-number-format';
import { useEffect, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { getPlanoAplicacao } from 'src/services/Planejamento/FundoNacional/planoAplicacao';
import { getEixosFinanciados } from 'src/services/Planejamento/FundoNacional/eixoFinanciado';
import { getMetasFisicas } from 'src/services/Planejamento/FundoNacional/metas';
import { tratandoValorCentavos } from 'src/config/utils';
import TextFieldMoney from 'src/components/Forms/TextFieldMoney';

export default function FormularioRemanejamento({ setValue }: { setValue: any }) {
  const [instituicoesRetirada, setInstituicoesRetirada] = useState([]);
  const [instituicoesRecebida, setInstituicoesRecebida] = useState([]);
  const [valorDisponivel, setValorDisponivel] = useState<number>();

  const planoAplicacaoId = useWatch({ name: 'planoAplicacaoId' });
  const eixoFinanciadoId = useWatch({ name: 'eixoFinanciadoId' });
  const natureza = useWatch({ name: 'natureza' });
  const metaFisicaRetirada = useWatch({ name: 'metaFisicaRetiradaId' });

  const metaFisicaRecebida = useWatch({ name: 'metaFisicaRecebidaId' });
  const instituicaoRetiradaId = useWatch({ name: 'instituicaoRetiradaId' });

  const { data: planoAplicacao } = useQuery({
    queryKey: ['getExercicioOrcamentario'],
    queryFn: getPlanoAplicacao,
  });

  const { data: eixosFinanciados, refetch: RefecthEixos } = useQuery({
    queryKey: ['getEixosFinanciados', planoAplicacaoId],
    queryFn: getEixosFinanciados,
    enabled: !!planoAplicacaoId,
  });
  const include = ['metaInstituicaoCount', 'metaGeral', 'metasInstituicoes'];

  const buscarMetas = {
    include,
    planoAplicacaoId,
    eixoFinanciadoId,
    natureza,
  };
  const { data: metasFisicas } = useQuery<{ total: number; results: object[] }>({
    queryKey: ['getMetaFisica', planoAplicacaoId, eixoFinanciadoId, natureza],
    queryFn: () => getMetasFisicas(buscarMetas),
    enabled: !!planoAplicacaoId && !!eixoFinanciadoId && !!natureza,
  });

  useEffect(() => {
    if (metaFisicaRetirada) {
      const retirada: any = metasFisicas?.results.filter(
        (item: any) => item.id === metaFisicaRetirada
      );
      setInstituicoesRetirada(retirada[0].metasInstituicoes);
    }
    if (metaFisicaRecebida) {
      const recebida: any = metasFisicas?.results.filter(
        (item: any) => item.id === metaFisicaRecebida
      );
      setInstituicoesRecebida(recebida[0].metasInstituicoes);
    }
  }, [metaFisicaRecebida, metaFisicaRetirada, metasFisicas?.results]);
  useEffect(() => {
    if (instituicoesRetirada) {
      const valorDispo: any = instituicoesRetirada?.filter(
        (item: any) => item.id === instituicaoRetiradaId
      );
      setValorDisponivel(valorDispo[0] ? valorDispo[0].saldoEstimativo : 0);
      if (valorDispo[0]) {
        setValue('valorDisponivel', valorDispo[0].saldoEstimativo);
      }
    }
  }, [instituicaoRetiradaId, instituicoesRetirada, setValue]);
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12} lg={6}>
        <TextFieldRG select name="planoAplicacaoId" label="Exercício Orçamentário">
          {planoAplicacao?.results.map((item: any, indexExercicio: number) => (
            <MenuItem key={item} value={item.id} disabled={item.status === 'Pendente'}>
              {item.exercicioOrcamentario}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <TextFieldRG select name="natureza" label="Natureza">
          <MenuItem value="investimento">Investimento</MenuItem>
          <MenuItem value="custeio">Custeio</MenuItem>
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <TextFieldRG select name="eixoFinanciadoId" label="Eixo/Área Temática">
          {eixosFinanciados?.map((item: any, indexEixoFinanciado: number) => (
            <MenuItem key={indexEixoFinanciado} value={item.id}>
              {item.eixoNome}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>

      <Grid item xs={12} md={12} lg={8}>
        <TextFieldRG select name="metaFisicaRetiradaId" label="Meta Física - Retirada">
          {metasFisicas?.results.map((item: any) => (
            <MenuItem key={item.id} value={item.id} disabled={item.metaGeral.status === 'Pendente'}>
              {item.nome}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <TextFieldRG select name="instituicaoRetiradaId" label="Instituição - Retirada">
          {instituicoesRetirada.map((item: any) => (
            <MenuItem key={item.id} value={item.id}>
              {item.sigla}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <TextFieldRG select name="metaFisicaRecebidaId" label="Meta Física - Recebida">
          {metasFisicas?.results.map((item: any) => (
            <MenuItem key={item.id} value={item.id}>
              {item.nome}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <TextFieldRG select name="instituicaoRecebidaId" label="Instituição - Recebida">
          {instituicoesRecebida.map((item: any) => (
            <MenuItem key={item.id} value={item.id} disabled={item.id === instituicaoRetiradaId}>
              {item.sigla}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        {!!valorDisponivel && (
          <TextFieldRG
            label="Valor Disponível"
            value={`R$ ${tratandoValorCentavos(valorDisponivel)}`}
            disabled
            name="valorDisponivel"
          />
        )}

        {!valorDisponivel && (
          <TextFieldRG label="Valor Disponível" value="R$ 0,00" disabled name="valorDisponivel" />
        )}
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <TextFieldMoney label="Valor Remanejado" name="valorRemanejado" />
      </Grid>
      <Grid item xs={12} md={12} lg={12}>
        <TextFieldRG label="Processo SEI" name="processoSei" />
      </Grid>
      <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
        <Button variant="contained" color="success" type="submit" startIcon={<SaveIcon />}>
          Cadastrar
        </Button>
      </Grid>
    </Grid>
  );
}
