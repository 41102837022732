import Endpoints from 'src/services/endpoints';
import { Api } from 'src/services/Api';
import { DataPlano } from 'src/pages/Planejamento/FundoNacional/PlanoAplicacao/Formulario/type';

// GET
export async function getExericioOrcamentario() {
  const response = await Api.get(Endpoints.planoAplicacaoExercicioEixos);
  return response.data;
}

export async function getPlanoAplicacao({ queryKey }: { queryKey: any }) {
  const [_key, skip, take, search, include] = queryKey;
  const filters = { search };
  const response = await Api.get(Endpoints.planoAplicacao, {
    params: {
      skip,
      take,
      filters,
      include,
    },
  });
  return response.data;
}

export async function getPlanoAplicacaoEixosFinanciados({ queryKey }: { queryKey: any }) {
  const [_key, id, skip, take] = queryKey;
  const response = await Api.get(`${Endpoints.planoAplicacaoEixos}/${id}`, {
    params: {
      skip,
      take,
    },
  });
  return response.data;
}

export async function getPlanoAplicacaoId({ queryKey }: { queryKey: any }) {
  const [_key, id, include] = queryKey;
  const response = await Api.get(`${Endpoints.planoAplicacao}/${id}`, {
    params: {
      id,
      include,
    },
  });
  return response.data;
}

// POST

export async function postCadastrarPlanoAplicacao(dadosplano: DataPlano) {
  const result = await Api.post(Endpoints.planoAplicacao, dadosplano);
  return result;
}

// PATCH
export async function patchAutorizarPlano(dadosAutorizacao: { id: string }) {
  const response = await Api.patch(`${Endpoints.planoAplicacao}/autorizar/${dadosAutorizacao.id}`, {
    dadosAutorizacao,
  });
  return response.data;
}
export async function AutorizarPlano(dadosAutorizacao: any) {
  const response = await Api.patch(`${Endpoints.planoAplicacao}/${dadosAutorizacao.id}/autorizar`, {
    dadosAutorizacao,
  });
  return response.data;
}

export async function patchAtualizarPlanoEixos(dadosPlano: DataPlano) {
  const response = await Api.patch(`${Endpoints.planoAplicacao}/${dadosPlano.planoAplicacao.id}`, {
    ...dadosPlano,
  });
  return response.data;
}
// DELETE
