import { Grid, Button, MenuItem } from '@mui/material';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import SaveIcon from '@mui/icons-material/Save';

export default function AeronaveForms() {
  return (
    <Grid container spacing={2}>
      <Grid item md={4} xs={12}>
        <TextFieldRG select label="Tipo Uso" name="tipoUso">
          <MenuItem value="aeroMedicoBombeiro">Aeromédico/Bombeiro</MenuItem>
          <MenuItem value="policial">Policial</MenuItem>
          <MenuItem value="vip">VIP</MenuItem>
        </TextFieldRG>
      </Grid>
      <Grid item md={4} xs={12}>
        <TextFieldRG select label="Situação" name="status">
          <MenuItem value="ativo">Ativo</MenuItem>
          <MenuItem value="inativo">Inativo</MenuItem>
        </TextFieldRG>
      </Grid>
      <Grid item md={4} xs={12}>
        <TextFieldRG label="Prefixo" name="prefixo" />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextFieldRG label="Apelido" name="apelido" />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextFieldRG label="Fabricante" name="fabricante" />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextFieldRG label="Modelo" name="modelo" />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextFieldRG label="Nº Série" name="numeroSerie" />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextFieldRG label="Ano de Fabricação" name="anoFabricacao" />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextFieldRG label="Horas Inciais" name="horasIniciais" />
      </Grid>
      <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
        <Button variant="contained" color="success" type="submit" startIcon={<SaveIcon />}>
          Cadastrar
        </Button>
      </Grid>
    </Grid>
  );
}
