import { useEffect, useState } from 'react';
import { Box, Grid, IconButton, Chip, Modal, Alert, styled, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DrawIcon from '@mui/icons-material/Draw';
import { useQuery } from '@tanstack/react-query';
import {
  diferencaEmDias,
  tratandoData,
  tratandoValor,
  tratandoValorCentavos,
} from 'src/config/utils';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import { getMetaFisica } from 'src/services/Planejamento/FundoNacional/metas';

const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: '11px',
  textAlign: 'center',

  fontWeight: 700,
  color: '#555555',
  textTransform: 'uppercase',
}));
const TableCellBody = styled(TableCell)(({ theme }) => ({
  textAlign: 'center',
  fontSize: '14px',
  color: '#555555',
  textTransform: 'uppercase',
}));

export default function MetaFisicaFinanceiro({
  open,
  id,
  handleClose,
}: {
  open: boolean;
  id: string;
  handleClose: () => void;
}) {
  const include = ['metasInstituicoes'];
  const { data: rowsMeta, isPending } = useQuery({
    queryKey: ['getMetaFisicaInst', id, include],
    queryFn: () => getMetaFisica(id, include),
  });

  if (isPending) {
    return <ModalCarregamento isOpen={isPending} />;
  }
  return (
    <div>
      {rowsMeta && (
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              background: 'white',
              borderRadius: '15px',
              p: 5,
              width: '80%',
              maxHeight: '650px',
              overflow: 'auto',
              scrollbarWidth: 'none',
              margin: 'auto',
              mt: 3,
            }}
          >
            <Grid container>
              <Grid md={12} xs={12} sx={{ textAlign: 'right' }}>
                <IconButton aria-label="delete" onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Grid>
              <Grid md={12} xs={12}>
                <Alert
                  sx={{ background: '#00ACC1', color: 'white', fontWeight: '700' }}
                  icon={<DrawIcon sx={{ color: 'white' }} />}
                >
                  Meta Física - FINANCEIRO
                </Alert>

                <TableContainer>
                  {/* DADOS DA META - ANO - EIXO -META GERAL */}
                  <Table sx={{ minWidth: 700, mt: 2 }} aria-label="spanning table">
                    <TableHead sx={{ background: '#f5f5f5' }}>
                      <TableRow sx={{ background: '#f5f5f5' }}>
                        <TableCellHead>INSTITUIÇÃO</TableCellHead>
                        <TableCellHead>VALOR INICIAL</TableCellHead>
                        <TableCellHead>
                          VALOR REMANEJADO <br /> RECEBIDO
                        </TableCellHead>
                        <TableCellHead>
                          VALOR REMANEJADO <br /> RETIRADO
                        </TableCellHead>
                        <TableCellHead>RENDIMENTO ALOCADO</TableCellHead>
                        <TableCellHead>VALOR EMPENHADO</TableCellHead>
                        <TableCellHead>VALOR EXECUTADO</TableCellHead>
                        <TableCellHead>SALDO ESTIMATIVO</TableCellHead>
                        <TableCellHead>SALDO REAL</TableCellHead>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowsMeta.metasInstituicoes.map((item: any, index: number) => (
                        <TableRow key={index}>
                          <TableCellBody>{item.sigla}</TableCellBody>
                          <TableCellBody>
                            R$ {tratandoValorCentavos(item.valorInicial)}
                          </TableCellBody>
                          <TableCellBody>
                            R$ {tratandoValorCentavos(item.saldoRecebido)}
                          </TableCellBody>
                          <TableCellBody>
                            R$ {tratandoValorCentavos(item.saldoRetirado)}
                          </TableCellBody>
                          <TableCellBody>
                            R$ {tratandoValorCentavos(item.saldoRecebidoRendimentos)}
                          </TableCellBody>
                          <TableCellBody>
                            R$ {tratandoValorCentavos(item.valorEmpenhado)}
                          </TableCellBody>
                          <TableCellBody>
                            R$ {tratandoValorCentavos(item.valorExecutado)}
                          </TableCellBody>
                          <TableCellBody>
                            R$ {tratandoValorCentavos(item.saldoEstimativo)}
                          </TableCellBody>
                          <TableCellBody>R$ {tratandoValorCentavos(item.saldoReal)}</TableCellBody>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Box>
        </Modal>
      )}
    </div>
  );
}
