import { Alert, Button, Grid, Grow, IconButton, Modal, Stack, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { useContext, useState } from 'react';
import { CustomContainerCad } from 'src/components/CustomContainer/custom-container';
import FormsProvider from 'src/components/Forms/Provider';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import DrawIcon from '@mui/icons-material/Draw';
import { AuthContext } from 'src/Context/ContextUser';
import { useMutation } from '@tanstack/react-query';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import { postCadastrarUnidade } from 'src/services/Admin/post';
import { FormularioUnidade } from '../Formulario';
import { schemaUnidade } from './type';

export default function CadastrarUnidade({
  open,
  handleClose,
  RefecthUnidades,
}: {
  open: boolean;
  handleClose: any;
  RefecthUnidades?: any;
}) {
  const methods = useForm({
    resolver: yupResolver(schemaUnidade),
  });
  const { state } = useContext(AuthContext);

  const { mutate, isPending } = useMutation({
    mutationFn: postCadastrarUnidade,
    onSuccess: (data) => {
      ToastFun('Unidade cadastrada com sucesso!', 'success');
      handleClose();
      RefecthUnidades();
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });
  if (isPending) {
    return <ModalCarregamento isOpen={isPending} />;
  }
  const onSubmit = async (data: any) => {
    mutate(data);
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          background: 'white',
          borderRadius: '15px',
          p: 5,
          width: {
            xl: '58%',
            md: '75%',
            sm: '70%',
          },
          maxHeight: '95%',
          overflow: 'auto',
          scrollbarWidth: 'none',
          margin: 'auto',
          mt: 3,
        }}
      >
        <Grid container>
          <Grid md={12} xs={12} sx={{ textAlign: 'right' }}>
            <IconButton aria-label="delete" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid md={12} xs={12}>
            <Alert
              sx={{ background: '#019362', color: 'white', fontWeight: '700' }}
              icon={<DrawIcon sx={{ color: 'white' }} />}
            >
              Unidades - Cadastro
            </Alert>
            <Box sx={{ my: 1 }}>
              <FormsProvider methods={methods} handleSubmit={onSubmit}>
                <FormularioUnidade />
              </FormsProvider>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
