import { useState } from 'react';
import { Box, Grid, Stack, Button, IconButton, Chip, TextField, Grow } from '@mui/material';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import { NODE_ENV } from 'src/config/constants';

export default function Dashboard() {
  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={2}>
        <CustomBreadcrumbs
          heading="Dashboard"
          links={[{ name: 'Planejamento', href: '/planejamento' }]}
        />
        <Box>
          {NODE_ENV === 'production' ? (
            <iframe
              title="DPLAGE"
              width="100%"
              height="850px"
              style={{
                border: 'none',
                boxShadow: '1px 1px 10px 1px #c4c4c4',
                borderRadius: '10px',
              }}
              src="https://app.powerbi.com/view?r=eyJrIjoiNmUwNjc4MGYtNGFhNi00ZDc3LTg1MGEtMWM5ODhhYzQ2MmU5IiwidCI6IjdmZDRjZTRmLWRjN2UtNDZhMS04ODlhLTg4OWI1ZWFhYjVmZiJ9"
            />
          ) : (
            <iframe
              title="DPLAGE"
              width="100%"
              height="850px"
              style={{
                border: 'none',
                boxShadow: '1px 1px 10px 1px #c4c4c4',
                borderRadius: '10px',
              }}
              src="https://app.powerbi.com/view?r=eyJrIjoiYzI4MTkxN2ItNmQ1OS00Yjk5LWEyMjItNGJmM2U5ZDRjY2M2IiwidCI6IjdmZDRjZTRmLWRjN2UtNDZhMS04ODlhLTg4OWI1ZWFhYjVmZiJ9"
            />
          )}
        </Box>
      </Stack>
    </Box>
  );
}
