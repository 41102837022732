import { useEffect, useMemo, useState } from 'react';
import { Box, Grid, Stack, Button, IconButton, Chip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import { useQuery } from '@tanstack/react-query';
import TableContainer from 'src/components/Table';
import { GridColDef } from '@mui/x-data-grid';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { tratandoData } from 'src/config/utils';
import {
  getFlagsPermissoes,
  getGrupoPermissoes,
  getUnidades,
  getUsuarios,
} from 'src/services/Admin/get';
import { formatarCPF } from 'src/utils/functions';
import CadastrarGrupo from './Cadastro';
import AtualizarGrupo from './Atualizar';

export default function Permissoes() {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 7,
  });

  const skip = paginationModel.page * paginationModel.pageSize;
  const take = paginationModel.pageSize;
  const [open, setOpen] = useState(false);

  // BUSCAR DADOS DOS GRUPOS
  const [idGP, setIdGP] = useState<string>('');
  const [typeAction, setType] = useState<string>('');

  const handleOpen = (type: 'Criar' | 'Editar', id?: string) => {
    if (id) {
      setIdGP(id);
    }
    setType(type);
    setOpen(true);
  };

  const handleClose = () => {
    setType('');
    setIdGP('');
    setOpen(false);
  };

  const { data: rowsGrupos, refetch: refetchGrupo } = useQuery({
    queryKey: ['getGrupos'],
    queryFn: getGrupoPermissoes,
  });

  const columns: GridColDef[] = [
    {
      field: 'nome',
      headerName: 'NOME',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Box sx={{ textAlign: 'right' }}>
          <IconButton color="warning" onClick={() => handleOpen('Editar', params.row.id)}>
            <ModeEditIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={2}>
        <CustomBreadcrumbs
          heading="Usuários"
          links={[
            { name: 'Administrador', href: '/administrador' },
            { name: 'Usuários', href: '/administrador/usuarios' },
          ]}
        />
        <Grid container spacing={2}>
          <Grid xs={12} md={12} sx={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              sx={{ mt: -5 }}
              onClick={() => handleOpen('Criar')}
            >
              Novo Grupo
            </Button>
          </Grid>
          <Grid xs={12} mt={2}>
            <CustomContainerList title="Grupo de Permissões">
              {rowsGrupos && (
                <TableContainer
                  columns={columns}
                  rows={rowsGrupos.results}
                  rowCount={rowsGrupos.total}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                />
              )}
            </CustomContainerList>
          </Grid>
        </Grid>
        {typeAction === 'Criar' && (
          <CadastrarGrupo open={open} handleClose={handleClose} refetchGrupo={refetchGrupo} />
        )}
        {typeAction === 'Editar' && idGP && (
          <AtualizarGrupo
            open={open}
            handleClose={handleClose}
            refetchGrupo={refetchGrupo}
            id={idGP}
          />
        )}
      </Stack>
    </Box>
  );
}
