import { DadosVinculadosEmpenho } from 'src/pages/Planejamento/FundoNacional/Despesas/Empenhos/Formulario/type';
import { Api, ApiEmpenho } from 'src/services/Api';
import Endpoints from 'src/services/endpoints';

// GET
export async function getEmpenhosAPI(numeroEmpenho: string) {
  const response = await ApiEmpenho.get(`${Endpoints.empenhos}/${numeroEmpenho}`);
  return response.data;
}
export async function getPagamentoEmpenhoAPI(numeroEmpenho: string) {
  const response = await ApiEmpenho.get(`${Endpoints.empenhos}/pagamento/${numeroEmpenho}`);
  return response.data;
}
export async function postDespesas(dadosDespesas: DadosVinculadosEmpenho) {
  const response = await Api.post(Endpoints.despesas, dadosDespesas);
  return response.data;
}
export async function getDespesas({ queryKey }: { queryKey: any }) {
  const [_key, skip, take, search, include] = queryKey;
  const filters = { search };
  const response = await Api.get(Endpoints.despesas, {
    params: {
      skip,
      take,
      filters,
      include,
    },
  });
  return response.data;
}
export async function getDespesasId({ queryKey }: { queryKey: any }) {
  const [_key, id] = queryKey;
  const response = await Api.get(`${Endpoints.despesas}/${id}`);

  return response.data;
}
export async function getDespesasMetaFisicaId({ queryKey }: { queryKey: any }) {
  const [_key, skip, take, id] = queryKey;
  const response = await Api.get(`${Endpoints.despesas}/meta/${id}`, {
    params: {
      skip,
      take,
    },
  });

  return response.data;
}

// POST
// PATCH
// DELETE
export async function ExcluirDespesa(id: string) {
  const response = await Api.delete(`${Endpoints.despesas}/${id}`);
  return response.data;
}
