/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SaveIcon from '@mui/icons-material/Save';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import { Grid, Typography, Button, TextField, IconButton } from '@mui/material';
import { funcoesData, Mod, modulosData } from 'src/pages/Admin/Usuarios/typeUsuario';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useWatch } from 'react-hook-form';

import TextFieldRGMaks from 'src/components/Forms/TextFieldRG/TextFieldMask';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name: string, modulos: string[], theme: Theme) {
  return {
    fontWeight:
      modulos.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
type Permissao = {
  nome: string;
};
type FormsUsuario = {
  setValue: any;
  modulos: string[];
  funcoes: string[];
  setModulos: any;
  setFuncoes: any;
};

export default function FormularioCadastrarUsuario({
  setValue,
  modulos,
  funcoes,
  setModulos,
  setFuncoes,
}: FormsUsuario) {
  const [countPermissao, setCountPermissao] = useState<number>(0);
  const [arrayPermissao, setArrayPermissao] = useState<number[]>([]);

  const permissoes = useWatch({ name: 'permissoes' });

  const novaPermissao = () => {
    setArrayPermissao((previs) => [...previs, countPermissao]);
    setCountPermissao(countPermissao + 1);
  };

  function excluirPermissao(index: number) {
    const novoArray = arrayPermissao.slice();
    novoArray.splice(index, 1);
    setArrayPermissao(novoArray);
    permissoes.splice(index, 1);
    setValue('permissoes', permissoes);
  }

  return (
    <Grid container spacing={2} sx={{ color: '#555555' }}>
      <Grid item xs={12} md={4}>
        <Typography variant="subtitle2">Email</Typography>
        <TextFieldRG type="text" name="email" label="" />
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="subtitle2">Nome</Typography>
        <TextFieldRG type="text" name="nome" label="" />
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="subtitle2">CPF</Typography>
        <TextFieldRGMaks label="" name="cpf" mask="___.___.___-__" />
      </Grid>
      <Grid item xs={12} md={4}>
        <Typography variant="subtitle2">Senha</Typography>
        <TextFieldRG type="password" name="senha" label="" />
      </Grid>
      <Grid item xs={12} md={10} sx={{ my: 2 }}>
        <Typography variant="subtitle2">Permissões</Typography>
      </Grid>
      <Grid item xs={12} md={2} sx={{ textAlign: 'right', my: 2 }}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => novaPermissao()}
        >
          Nova Permissão
        </Button>
      </Grid>
      {arrayPermissao.map((item, index) => (
        <>
          <Grid item xs={12} md={4} sx={{}}>
            <Typography variant="subtitle2">Função</Typography>
            <TextFieldRG select name={`permissoes[${index}].funcao`} label="">
              {funcoesData.map((name) => (
                <MenuItem
                  key={name}
                  value={`${name
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f\s]/g, '')
                    .toLocaleLowerCase()}`}
                >
                  {name}
                </MenuItem>
              ))}
            </TextFieldRG>
          </Grid>

          <Grid item xs={12} md={4} sx={{}}>
            <Typography variant="subtitle2">Módulo</Typography>
            <TextFieldRG select name={`permissoes[${index}].modulo`} label="">
              {Mod?.map((name) => (
                <MenuItem
                  key={name}
                  value={`${name
                    .normalize('NFD')
                    .replace(/[\u0300-\u036f\s]/g, '')
                    .toLocaleLowerCase()}`}
                >
                  {name}
                </MenuItem>
              ))}
            </TextFieldRG>
          </Grid>
          <Grid item xs={12} md={3} sx={{ textAlign: 'right', mt: 3 }}>
            <IconButton
              aria-label="delete"
              color="error"
              sx={{ my: 2 }}
              onClick={() => excluirPermissao(index)}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        </>
      ))}

      <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
        <Button variant="contained" color="success" type="submit" startIcon={<SaveIcon />}>
          Cadastrar
        </Button>
      </Grid>
    </Grid>
  );
}
