import { useEffect, useState } from 'react';
import { Alert, Grid, IconButton, Modal } from '@mui/material';
import Box from '@mui/material/Box';
import FormsProvider from 'src/components/Forms/Provider';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import DrawIcon from '@mui/icons-material/Draw';
import { postCadastrarGruposPermissoes } from 'src/services/Admin/post';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import { getGrupoPermissoes, getGrupoPermissoesID } from 'src/services/Admin/get';
import { GrupoPermissoes } from 'src/services/Admin/patch';
import GrupoForms from '../Formulario';
import { schemaGrupo } from '../Cadastro/type';

export default function AtualizarGrupo({
  id,
  open,
  handleClose,
  refetchGrupo,
}: {
  id: string;
  open: boolean;
  handleClose: any;
  refetchGrupo: any;
}) {
  const { data: rowsGrupos, refetch } = useQuery({
    queryKey: ['getGrupos', id],
    queryFn: getGrupoPermissoesID,
    enabled: !!id,
  });
  const [permissoes, setPermissoes] = useState<string[]>([]);
  const methods = useForm({
    resolver: yupResolver(schemaGrupo),
    defaultValues: rowsGrupos,
  });
  const { setValue } = methods;

  const { mutate, isPending } = useMutation({
    mutationFn: GrupoPermissoes,
    onSuccess: (data) => {
      ToastFun('Grupo atualizado com sucesso!', 'success');
      handleClose();
      refetchGrupo();
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });
  useEffect(() => {
    if (rowsGrupos) {
      methods.reset(rowsGrupos);
      setPermissoes(rowsGrupos.permissoes);
    }
  }, [methods, rowsGrupos]);

  const onSubmit = async (data: any) => {
    mutate(data);
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          background: 'white',
          borderRadius: '15px',
          p: 5,
          width: {
            xl: '58%',
            md: '75%',
            sm: '70%',
          },
          maxHeight: '95%',
          overflow: 'auto',
          scrollbarWidth: 'none',
          margin: 'auto',
          mt: 3,
        }}
      >
        <Grid container>
          <Grid md={12} xs={12} sx={{ textAlign: 'right' }}>
            <IconButton aria-label="delete" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid md={12} xs={12}>
            <Alert
              sx={{ background: '#4B96DD', color: 'white', fontWeight: '700' }}
              icon={<DrawIcon sx={{ color: 'white' }} />}
            >
              Grupos - Editar
            </Alert>
            <Box sx={{ my: 1 }}>
              <FormsProvider methods={methods} handleSubmit={onSubmit}>
                <GrupoForms setValue={setValue} permissoes={permissoes} />
              </FormsProvider>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
