import { useState } from 'react';
import { Box, Grid, Button, IconButton, Chip } from '@mui/material';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import { useMutation, useQuery } from '@tanstack/react-query';
import TableContainer from 'src/components/Table';
import { GridColDef } from '@mui/x-data-grid';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import { tratandoValorCentavos } from 'src/config/utils';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  AutorizarAlocacaoRendimento,
  getRendimentosAlocacao,
} from 'src/services/Planejamento/FundoNacional/rendimento';
import ModalAutorizar from 'src/components/Modal/Autorizar';

export default function RendimentosAlocacao() {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 7,
  });

  const skip = paginationModel.page * paginationModel.pageSize;
  const take = paginationModel.pageSize;

  const [id, setId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checked, setChecked] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('Pendente');

  const include = ['eixoNome', 'exercicioOrcamentario', 'usuarioCreated', 'conta'];

  const { data: rows, refetch: RefecthRendimentos } = useQuery({
    queryKey: ['getRendimentos', skip, take, search, include],
    queryFn: getRendimentosAlocacao,
  });
  const { mutate } = useMutation({
    mutationFn: AutorizarAlocacaoRendimento,
    onSuccess: (data) => {
      ToastFun('Alocação autorizada com sucesso', 'success');
      RefecthRendimentos();
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleButtonClick = (idRendimento: string) => {
    setId(idRendimento);
    setIsModalOpen(true);
  };

  const columns: GridColDef[] = [
    {
      field: 'exercicioOrcamentario',
      headerName: 'PLANO DE APLICAÇÃO',
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'eixoNome',
      headerName: 'EIXO',

      minWidth: 150,
      flex: 1,
    },
    {
      field: 'conta',
      headerName: 'CONTA',

      minWidth: 150,
      flex: 1,
    },
    {
      field: 'nomeMetaFisica',
      headerName: 'META FÍSICA',

      minWidth: 150,
      flex: 1,
    },
    {
      field: 'nomeInstituicao',
      headerName: 'INSTITUIÇÃO',
      minWidth: 150,
      flex: 1,
    },

    {
      field: 'valor',
      headerName: 'VALOR',
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <>R$ {tratandoValorCentavos(params.row.valor)}</>,
    },
    {
      field: 'status',
      headerName: 'SITUAÇÃO',
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          sx={{ color: 'white' }}
          color={params.row.status === 'Pendente' ? 'warning' : 'success'}
        />
      ),
    },

    {
      field: 'actions',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Button
            fullWidth
            variant="soft"
            color="success"
            startIcon={<TaskAltIcon />}
            sx={{ fontSize: '12px' }}
            onClick={() => handleButtonClick(params.row.id)}
          >
            Autorizar
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ m: 'auto' }}>
      <Grid container spacing={2}>
        <Grid xs={12} mt={2}>
          <CustomContainerList title="Destinação - Rendimentos">
            {rows && (
              <TableContainer
                columns={columns}
                rows={rows.results}
                rowCount={rows.total}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
              />
            )}
          </CustomContainerList>
        </Grid>
      </Grid>
      {id && (
        <ModalAutorizar
          isOpen={isModalOpen}
          nomeAcao="Alocação"
          id={id}
          mutate={mutate}
          handleClose={handleCloseModal}
        />
      )}
    </Box>
  );
}
