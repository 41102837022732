import { useState } from 'react';
import {
  Box,
  Grid,
  Stack,
  Button,
  IconButton,
  Chip,
  TextField,
  Grow,
  Typography,
} from '@mui/material';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import { useMutation, useQuery } from '@tanstack/react-query';
import TableContainer from 'src/components/Table';
import { GridColDef } from '@mui/x-data-grid';
import { tratandoValorCentavos } from 'src/config/utils';
import { useNavigate } from 'react-router-dom';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import ModalAutorizar from 'src/components/Modal/Autorizar';
import {
  AutorizarRendimento,
  getRendimentos,
} from 'src/services/Planejamento/FundoNacional/rendimento';

export default function RendimentosTabelaAutorizacao() {
  const navigate = useNavigate();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 7,
  });

  const skip = paginationModel.page * paginationModel.pageSize;
  const take = paginationModel.pageSize;
  const [id, setId] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [search, setSearch] = useState<string>('Pendente');

  const include = ['eixoNome', 'exercicioOrcamentario', 'usuarioCreated', 'conta'];

  const {
    data: rows,
    refetch: RefecthRendimentos,
    isPending,
  } = useQuery({
    queryKey: ['getRendimentos', skip, take, search, include],
    queryFn: getRendimentos,
  });
  const { mutate } = useMutation({
    mutationFn: AutorizarRendimento,
    onSuccess: (data) => {
      ToastFun('Rendimento autorizado com sucesso', 'success');
      RefecthRendimentos();
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });
  const handleButtonClick = (idRendimento: string) => {
    setId(idRendimento);
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setId(null);
  };

  if (isPending) {
    return <ModalCarregamento isOpen={isPending} />;
  }

  const columns: GridColDef[] = [
    {
      field: 'exercicioOrcamentario',
      headerName: 'ANO',
      flex: 1,
      minWidth: 50,
    },
    {
      field: 'eixoNome',
      headerName: 'EIXO FINANCIADO',
      flex: 1,
      minWidth: 150,
    },

    {
      field: 'conta',
      headerName: 'CONTA',
      flex: 1,
      minWidth: 150,
    },
    {
      field: 'valor',
      headerName: 'RENDIMENTO',
      flex: 1,
      minWidth: 150,
      renderCell: (params) => <>R$ {tratandoValorCentavos(params.row.valor)}</>,
    },
    {
      field: 'usuarioCreated',
      headerName: 'CRIADO POR',
      flex: 1,
      minWidth: 150,
    },

    {
      field: 'status',
      headerName: 'SITUAÇÃO',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          sx={{ color: 'white' }}
          color={params.row.status === 'Pendente' ? 'warning' : 'success'}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Box>
          <Button
            fullWidth
            variant="soft"
            color="success"
            startIcon={<TaskAltIcon />}
            sx={{ fontSize: '12px' }}
            onClick={() => handleButtonClick(params.row.id)}
          >
            Autorizar
          </Button>
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={2}>
        <Grid container spacing={2}>
          <Grid xs={12} mt={2}>
            <CustomContainerList title="Rendimentos">
              {rows && (
                <TableContainer
                  columns={columns}
                  rows={rows.results}
                  rowCount={rows.total}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                />
              )}
            </CustomContainerList>
          </Grid>
        </Grid>
      </Stack>
      {id && (
        <ModalAutorizar
          isOpen={isModalOpen}
          nomeAcao="Rendimento"
          id={id}
          mutate={mutate}
          handleClose={handleCloseModal}
        />
      )}
    </Box>
  );
}
