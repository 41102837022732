import { useEffect, useState } from 'react';
import { Grid, Button, MenuItem, Typography, Card, Alert, CardContent, Box } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import AddIcon from '@mui/icons-material/Add';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import { useQuery } from '@tanstack/react-query';
import {
  FieldValues,
  UseFormSetValue,
  UseFormUnregister,
  UseFormWatch,
  useWatch,
} from 'react-hook-form';
import { getPlanoAplicacao } from 'src/services/Planejamento/FundoNacional/planoAplicacao';
import { getMetasGerais } from 'src/services/Planejamento/FundoNacional/metas';
import { tratandoValorCentavos } from 'src/config/utils';
import { ToastFun } from 'src/config/functions';
import {
  getEixoFinanciadoSaldo,
  getEixosFinanciados,
} from 'src/services/Planejamento/FundoNacional/eixoFinanciado';
import { MetaFisicaForms } from './MetaFisica';

interface Plano {
  setValue: UseFormSetValue<FieldValues>;
  unregister: UseFormUnregister<FieldValues>;
  watch: any;
}
export default function MetasFisicas({ setValue, unregister, watch }: Plano) {
  const [metasFisicas, setMetasFisicas] = useState<number[]>([0]);

  const planoAplicacaoId = useWatch({ name: 'planoAplicacaoId' });
  const eixoFinanciadoId = useWatch({ name: 'eixoFinanciadoId' });

  const { data: planoAplicacao } = useQuery({
    queryKey: ['getExercicioOrcamentario'],
    queryFn: getPlanoAplicacao,
  });

  const { data: eixosFinanciados, refetch: RefecthEixos } = useQuery({
    queryKey: ['getEixosFinanciados', planoAplicacaoId],
    queryFn: getEixosFinanciados,
    enabled: !!planoAplicacaoId,
  });
  const dados = {
    planoAplicacaoId,
    eixoFinanciadoId,
  };
  const {
    data: rows,
    refetch: RefecthPlano,
    isPending,
  } = useQuery({
    queryKey: ['getMetas', planoAplicacaoId, eixoFinanciadoId],
    queryFn: () => getMetasGerais(dados),
    enabled: !!planoAplicacaoId && !!eixoFinanciadoId,
  });

  const { data: eixoFinanciadoSaldo } = useQuery({
    queryKey: ['eixoFinanciadoSaldo', planoAplicacaoId, eixoFinanciadoId],
    queryFn: () => getEixoFinanciadoSaldo(eixoFinanciadoId),
    enabled: !!planoAplicacaoId && !!eixoFinanciadoId,
  });
  const novaMeta = () => {
    setMetasFisicas((prevMetas) => {
      const ultimoValor = prevMetas[prevMetas.length - 1] ?? 0;
      return [...prevMetas, ultimoValor + 1];
    });
  };

  const metasFisicasForms = useWatch({ name: 'metasFisicas' });

  const excluirMetaFisica = (index: number) => {
    if (metasFisicas.length > 1) {
      unregister(`metasFisicas[${index}]`);
      setMetasFisicas((prevMetas) => prevMetas.filter((eixo) => eixo !== index));
    } else {
      ToastFun('É necessário que ter ao menos uma Meta Física', 'error');
    }
  };
  useEffect(() => {
    if (metasFisicasForms) {
      setMetasFisicas([0]);
    }
    if (metasFisicasForms.length > 0) {
      const eixoAtualizar: number[] = [];
      metasFisicasForms.map((_item: any, index: number) => eixoAtualizar.push(index));
      setMetasFisicas(eixoAtualizar);
    }
  }, [metasFisicasForms]);

  return (
    <Grid container spacing={1} justifyContent="center">
      <Grid item xs={12} md={12} lg={4}>
        <TextFieldRG select name="planoAplicacaoId" label="Exercício Orçamentário">
          {planoAplicacao?.results.map((item: any, indexExercicio: number) => (
            <MenuItem key={indexExercicio} value={item.id}>
              {item.exercicioOrcamentario}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <Box display="grid">
          <TextFieldRG
            select
            name="eixoFinanciadoId"
            label="Eixo/Área Temática"
            sx={{
              mt: 2,
            }}
          >
            {eixosFinanciados?.map((item: any, indexEixoFinanciado: number) => (
              <MenuItem key={indexEixoFinanciado} value={item.id}>
                {item.eixoNome}
              </MenuItem>
            ))}
          </TextFieldRG>
        </Box>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <Box display="grid">
          <TextFieldRG
            select
            fullWidth
            name="metaGeralId"
            label="Meta Geral"
            sx={{
              mt: 2,
            }}
          >
            {rows?.results.map((item: any, indexMetaGeral: number) => (
              <MenuItem key={indexMetaGeral} value={item.id}>
                {item.nome}
              </MenuItem>
            ))}
          </TextFieldRG>
        </Box>
      </Grid>
      {eixoFinanciadoSaldo && eixosFinanciados.length > 0 && (
        <>
          <Grid item xs={12} sm={12} md={12} lg={3} sx={{ textAlign: 'center', mx: 1 }}>
            <Card sx={{ background: '#E8F7FF', color: '#4CC0FF' }}>
              <CardContent>
                <Typography sx={{ fontSize: 13, fontWeight: 600 }} gutterBottom>
                  Valor Disponível Investimento
                </Typography>

                <Typography sx={{ fontSize: 13, fontWeight: 600 }}>
                  R$ {tratandoValorCentavos(eixoFinanciadoSaldo.saldoInvestimento)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={3} sx={{ textAlign: 'center', mx: 1 }}>
            <Card sx={{ background: '#ECF2FF', color: '#5D87FF' }}>
              <CardContent>
                <Typography sx={{ fontSize: 13, fontWeight: 600 }} gutterBottom>
                  Valor Disponível Custeio
                </Typography>

                <Typography sx={{ fontSize: 13, fontWeight: 600 }}>
                  R$ {tratandoValorCentavos(eixoFinanciadoSaldo.saldoCusteio)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6.3} sx={{ textAlign: 'center' }}>
            <Alert variant="standard" severity="warning" sx={{ mx: 1 }}>
              Os valores cadastrados não devem exceder o valor disponível para este eixo!
            </Alert>
          </Grid>
        </>
      )}
      {/* METAS FISICAS */}
      <Grid item xs={12}>
        {metasFisicas.map((item, indexMeta) => (
          <MetaFisicaForms
            key={item}
            index={item}
            setValue={setValue}
            unregister={unregister}
            excluirMetaFisica={excluirMetaFisica}
            watch={watch}
          />
        ))}
      </Grid>
      <Grid item xs={12} md={12} mt={3} sx={{ my: 5 }}>
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={novaMeta}>
          Meta Física
        </Button>
      </Grid>
      <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
        <Button variant="contained" color="success" type="submit" startIcon={<SaveIcon />}>
          Cadastrar
        </Button>
      </Grid>
    </Grid>
  );
}
