import { Api } from 'src/services/Api';
import Endpoints from 'src/services/endpoints';

// GET
export async function getEixos({ queryKey }: { queryKey: any }) {
  const [_key, skip, take] = queryKey;
  const response = await Api.get(Endpoints.eixos, {
    params: {
      skip,
      take,
    },
  });
  return response.data;
}

export async function getEixosFinanciados({ queryKey }: { queryKey: any }) {
  const [_key, id] = queryKey;
  const response = await Api.get(`${Endpoints.eixoFinanciado}/plano/${id}`);
  return response.data;
}
export async function getEixoFinanciadoMetas({ queryKey }: { queryKey: any }) {
  const [_key, id, include] = queryKey;

  const response = await Api.get(`${Endpoints.eixoFinanciado}/metas/${id}`, {
    params: {
      include,
    },
  });
  return response.data;
}
export async function getEixoFinanciadoSaldo(id: string) {
  const response = await Api.get(`${Endpoints.eixoFinanciado}/saldo/${id}`);
  return response.data;
}
// POST
// PATCH
// DELETE
export async function ExcluirEixoFinanciado(dadosEixo: { id: string }) {
  const response = await Api.delete(`${Endpoints.eixoFinanciado}/${dadosEixo.id}`);
  return response.data;
}
