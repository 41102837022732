import Endpoints from 'src/services/endpoints';
import { Api } from 'src/services/Api';
import { FormValuesRendimento } from 'src/pages/Planejamento/FundoNacional/PlanoAplicacao/Rendimentos/Formulario/type';
import { RendimentoALocacao } from 'src/pages/Planejamento/FundoNacional/Financeiro/Destinacao/Formulario/type';

// GET
export async function getRendimentos({ queryKey }: { queryKey: any }) {
  const [_key, skip, take, search, include, conta, id] = queryKey;
  const filters = { search };
  const response = await Api.get(Endpoints.rendimento, {
    params: { skip, take, filters, include, conta, idPlano: id },
  });
  return response.data;
}
export async function getRendimentosAlocacao({ queryKey }: { queryKey: any }) {
  const [_key, skip, take, search, include] = queryKey;
  const filters = { search };
  const response = await Api.get(`${Endpoints.rendimento}/alocar`, {
    params: { skip, take, filters, include },
  });
  return response.data;
}
export async function getRendimentosPlano({ queryKey }: { queryKey: any }) {
  const [_key, id] = queryKey;
  const response = await Api.get(`${Endpoints.rendimento}/${id}`);

  return response.data;
}

export async function getRendimentoConta({ queryKey }: { queryKey: any }) {
  const [_key, eixoFinanciadoId, conta] = queryKey;
  const response = await Api.get(`${Endpoints.rendimento}/rendimento-conta`, {
    params: {
      eixoFinanciadoId,
      conta,
    },
  });

  return response.data;
}

// POST
export async function postCadastrarRendimento(dadosRendimento: FormValuesRendimento) {
  const result = await Api.post(Endpoints.rendimento, dadosRendimento);

  return result;
}
export async function postAlocarRendimento(dadosRendimento: RendimentoALocacao) {
  const result = await Api.post(`${Endpoints.rendimento}/alocar`, dadosRendimento);

  return result;
}

// PATCH
export async function AutorizarRendimento(dadosAutorizacao: any) {
  const response = await Api.patch(`${Endpoints.rendimento}/autorizar/${dadosAutorizacao.id}`, {
    dadosAutorizacao,
  });
  return response.data;
}
export async function AutorizarAlocacaoRendimento(dadosAutorizacao: any) {
  const response = await Api.patch(
    `${Endpoints.rendimento}/autorizar/alocacao/${dadosAutorizacao.id}`,
    {
      dadosAutorizacao,
    }
  );
  return response.data;
}

// DELETE
export async function ExcluirRendimento(dados: any) {
  const response = await Api.delete(`${Endpoints.rendimento}/${dados}`);
  return response.data;
}
export async function ExcluirRendimentoAlocacao(dados: any) {
  const response = await Api.delete(`${Endpoints.rendimento}/alocar/${dados}`);
  return response.data;
}
