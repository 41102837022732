import { useState } from 'react';
import { Box, Stack, Tab, Tabs } from '@mui/material';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import RendimentosDestinacao from './Destinacao';
import Remanejamentos from './Remanejamento';

export default function Financeiro() {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={2}>
        <CustomBreadcrumbs
          heading="Fundo Nacional de Segurança Pública"
          links={[
            { name: 'Planejamento', href: '/planejamento' },
            { name: 'Fundo Nacional', href: '/planejamento/fundonacional' },
            {
              name: 'Movimentação Financeira',
              href: '/planejamento/fundonacional/movimentacao-financeira',
            },
          ]}
        />
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange}>
              <Tab label="Destinação de Rendimentos" {...a11yProps(0)} />
              <Tab label="Remanejamentos" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <RendimentosDestinacao />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Remanejamentos />
          </CustomTabPanel>
        </Box>
      </Stack>
    </Box>
  );
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
