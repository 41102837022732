import { Navigate } from 'react-router-dom';
import { RoutePrivate } from 'src/Auth/RouteProtect';
import LayoutSidebar from 'src/components/Layout';
import Dashboard from 'src/pages/Planejamento/Dashboard';
import FundoNacionalMenu from 'src/pages/Planejamento/FundoNacional';
import Autorizacao from 'src/pages/Planejamento/FundoNacional/Autorizacao';
import MetaGeralVisualizarAutorizacao from 'src/pages/Planejamento/FundoNacional/Autorizacao/Visualizar/MetaGeral';
import PlanoAplicacaoVisualizarAutorizacao from 'src/pages/Planejamento/FundoNacional/Autorizacao/Visualizar/PlanoAplicacao';
import Empenhos from 'src/pages/Planejamento/FundoNacional/Despesas';
import CadastrarEmpenho from 'src/pages/Planejamento/FundoNacional/Despesas/Empenhos/Cadastrar';
import Financeiro from 'src/pages/Planejamento/FundoNacional/Financeiro';
import MetasPlano from 'src/pages/Planejamento/FundoNacional/Metas';
import CadastrarMetasFisicas from 'src/pages/Planejamento/FundoNacional/Metas/Cadastro/MetaFisica';
import MetaFisicaVisualizar from 'src/pages/Planejamento/FundoNacional/Metas/Visualizar/MetaFisica';
import PlanoAplicacao from 'src/pages/Planejamento/FundoNacional/PlanoAplicacao';
import CadastrarPlano from 'src/pages/Planejamento/FundoNacional/PlanoAplicacao/Cadastro';
import PlanoAplicacaoVisualizarTabs from 'src/pages/Planejamento/FundoNacional/PlanoAplicacao/Visualizar';
import Remanejamento from 'src/pages/Planejamento/FundoNacional/Financeiro/Remanejamento';

export const Planejamento = {
  element: <RoutePrivate element={<LayoutSidebar />} permission="PLANEJAMENTO" />,
  path: '/planejamento',
  children: [
    {
      element: <Navigate to="dashboard" />,
      path: '',
    },
    {
      element: <RoutePrivate element={<Dashboard />} permission="PLANEJAMENTO" />,
      path: 'dashboard',
    },
    {
      element: <RoutePrivate element={<FundoNacionalMenu />} permission="FUNDO_NACIONAL" />,
      path: 'fundonacional',
    },
    {
      element: (
        <RoutePrivate
          element={<PlanoAplicacao />}
          permission="PLANEJAMENTO_FUNDO_NACIONAL_LISTAR"
        />
      ),
      path: 'fundonacional/planodeaplicacao',
    },
    {
      element: (
        <RoutePrivate element={<CadastrarPlano />} permission="PLANEJAMENTO_FUNDO_NACIONAL_CRIAR" />
      ),
      path: 'fundonacional/planodeaplicacao/cadastro',
    },
    {
      element: (
        <RoutePrivate element={<MetasPlano />} permission="PLANEJAMENTO_FUNDO_NACIONAL_LISTAR" />
      ),
      path: 'fundonacional/metas',
    },
    {
      element: <RoutePrivate element={<Autorizacao />} permission="PLANEJAMENTO_*" />,
      path: 'fundonacional/autorizacao',
    },
    {
      element: (
        <RoutePrivate
          element={<PlanoAplicacaoVisualizarAutorizacao />}
          permission="PLANEJAMENTO_*"
        />
      ),
      path: 'fundonacional/autorizacao/plano/:id',
    },
    {
      element: (
        <RoutePrivate element={<MetaGeralVisualizarAutorizacao />} permission="PLANEJAMENTO_*" />
      ),
      path: 'fundonacional/autorizacao/meta/:id',
    },
    {
      element: (
        <RoutePrivate
          element={<MetaFisicaVisualizar />}
          permission="PLANEJAMENTO_FUNDO_NACIONAL_LISTAR"
        />
      ),
      path: 'fundonacional/metas/:id',
    },
    {
      element: (
        <RoutePrivate
          element={<PlanoAplicacaoVisualizarTabs />}
          permission="PLANEJAMENTO_FUNDO_NACIONAL_LISTAR"
        />
      ),
      path: 'fundonacional/plano/:id',
    },
    {
      element: (
        <RoutePrivate
          element={<Remanejamento />}
          permission="PLANEJAMENTO_FUNDO_NACIONAL_REMANEJAR"
        />
      ),
      path: 'fundonacional/remanejamento',
    },
    {
      element: (
        <RoutePrivate
          element={<CadastrarMetasFisicas />}
          permission="PLANEJAMENTO_FUNDO_NACIONAL_CRIAR"
        />
      ),
      path: 'fundonacional/metas/cadastro',
    },
    {
      element: (
        <RoutePrivate element={<Financeiro />} permission="PLANEJAMENTO_FUNDO_NACIONAL_LISTAR" />
      ),
      path: 'fundonacional/movimentacao-financeira',
    },
    {
      element: (
        <RoutePrivate element={<Empenhos />} permission="PLANEJAMENTO_FUNDO_NACIONAL_LISTAR" />
      ),
      path: 'fundonacional/despesas',
    },
    {
      element: (
        <RoutePrivate
          element={<CadastrarEmpenho />}
          permission="PLANEJAMENTO_FUNDO_NACIONAL_CRIAR"
        />
      ),
      path: 'fundonacional/despesas/cadastro',
    },
  ],
};
