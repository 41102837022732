import { Box, Button, Stack } from '@mui/material';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import Grid from '@mui/material/Unstable_Grid2';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import DescriptionIcon from '@mui/icons-material/Description';

interface CustomButtonProps {
  children: React.ReactNode;
  disabled?: boolean;
  icon: React.ReactNode;
  LinkApp: string;
}

function CustomButton({ children, icon, LinkApp, disabled }: CustomButtonProps) {
  return (
    <Button
      variant="soft"
      color="success"
      size="large"
      disabled={disabled}
      startIcon={icon}
      fullWidth
      href={`/ciopaer/aeronaves/${LinkApp}`}
      sx={{
        py: 6,
        borderRadius: 2,
        // width: 260,
      }}
    >
      {children}
    </Button>
  );
}

export default function AeronaveMenu() {
  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={3}>
        <CustomBreadcrumbs
          heading="Centro Integrado de Operações Aéreas
"
          links={[{ name: 'Menu', href: '/ciopaer/aeronave' }]}
        />

        <Grid container spacing={3}>
          <Grid xs={12} md={4} lg={3}>
            <CustomButton LinkApp="list" icon={<ConnectingAirportsIcon />}>
              Aeronaves
            </CustomButton>
          </Grid>

          <Grid xs={12} md={4} lg={3}>
            <CustomButton LinkApp="abastecimento" icon={<LocalGasStationIcon />}>
              Abastecimento
            </CustomButton>
          </Grid>
          <Grid xs={12} md={4} lg={3}>
            <CustomButton LinkApp="relatorio" icon={<DescriptionIcon />}>
              Relatórios
            </CustomButton>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
}
