import { useState, useContext, useEffect } from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { AuthContext } from 'src/Context/ContextUser';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Grid, MenuItem, Menu, Divider, Drawer } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MinvervaHorizontal from '../../assets/images/Minerva_hori_menu.png';
import MinvervaCab from '../../assets/images/minerva_cab.png';
import * as modules from '../MenuModulo/Modulos';
import ItemList from '../MenuModulo/LayoutMenu';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: boolean;
}>(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px + 10px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  background: '#FBFBFB',
  boxShadow: '1px 1px 0px #D7D7D7',
  paddingBottom: '2px',
  paddingTop: '20px',
  width: '100vh',

  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(98% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('md')]: {
      width: `calc(100%)`,
    },
  }),
  ...(!open && {
    width: `calc(100% - 85px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.up('sm')]: {
      width: `calc(100%)`,
    },
    [theme.breakpoints.down('md')]: {
      width: `calc(100%)`,
    },
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - 90px)`,
    },
  }),
}));
const DrawerMD = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    '& .MuiPaper-root': {
      margin: '10px',
      height: '98vh',
      borderRadius: '20px',
      background: '#FBFBFB',
      border: '0.5px solid #D0D0D0',
      boxShadow: '2px 2px 10px #dcdcdc',
    },
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  })
);

interface Modulo {
  title: string;
  link: string;
  icon: JSX.Element;
  permissao: string;
}
type Modulos = Modulo[];

export default function LayoutSidebar() {
  const { state, openSidebar, closeSidebar, logout } = useContext(AuthContext);

  const [modulosPrivados, setModulosPrivados] = useState<Modulos>([]);

  const location = useLocation();
  const navgation = useNavigate();

  const pathSegments = location.pathname.split('/').filter(Boolean);

  const moduleAtual = pathSegments[0];
  const submoduleAtual = pathSegments[1];

  const handleDrawer = () => {
    if (!state.sidebarOpen) {
      openSidebar();
    } else {
      closeSidebar();
    }
  };
  function LogoutUser() {
    navgation('/');
    logout();
  }

  const module = (modules as any)[moduleAtual];
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (
      state.usuario?.permissoes.includes('*') ||
      state.usuario?.permissoes.includes(`${moduleAtual.toUpperCase()}_*`)
    ) {
      setModulosPrivados(module);
    } else {
      const modulos = module.filter((mod: any) =>
        state.usuario?.permissoes.some((item) => item.includes(mod.permissao))
      );
      setModulosPrivados(modulos);
    }
  }, [module, state.usuario?.permissoes, moduleAtual]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="absolute" open={state.sidebarOpen}>
        <Toolbar>
          <Grid container>
            <Grid item md={6} xs={2} mt={1}>
              <IconButton
                onClick={handleDrawer}
                edge="start"
                sx={{
                  color: '#5D5959',
                }}
              >
                <MenuIcon />
              </IconButton>
            </Grid>

            <Grid
              item
              xs={8}
              sx={{
                display: {
                  md: 'none',
                },
              }}
            >
              <Box
                sx={{
                  textAlign: 'center',
                  display: {
                    xs: 'block',
                    sm: 'block',
                    md: 'none',
                  },
                }}
              >
                <Link to="/menu">
                  <img src={MinvervaHorizontal} alt="" width={175} />
                </Link>
              </Box>
            </Grid>
            <Grid item md={6} xs={2} mt={1} sx={{ textAlign: 'right' }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                sx={{
                  color: '#5D5959',
                }}
              >
                <AccountCircleIcon />
              </IconButton>

              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                sx={{ mt: 5 }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => LogoutUser()}>Sair</MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <DrawerMD
        variant="permanent"
        open={state.sidebarOpen}
        sx={{
          display: {
            xs: 'none',
            sm: 'none',
          },
          ...(!state.sidebarOpen && {
            display: {
              xs: 'none',
              md: 'block',
            },
          }),
          ...(state.sidebarOpen && {
            display: {
              xs: 'none',
              md: 'block',
            },
          }),
        }}
      >
        <DrawerHeader>
          <Box
            sx={{
              p: 1,
              ...(!state.sidebarOpen && {
                display: 'none',
              }),
            }}
          >
            <Link to="/menu">
              <img src={MinvervaHorizontal} alt="" width={175} />
            </Link>
            <Divider />
          </Box>
          <Box
            sx={{
              p: 1,
              ...(state.sidebarOpen && {
                display: 'none',
              }),
            }}
          >
            <Link to="/menu">
              <img src={MinvervaCab} alt="" />
            </Link>
            <Divider />
          </Box>
        </DrawerHeader>
        {modulosPrivados.map((item, index) => (
          <ItemList
            key={index}
            open={state.sidebarOpen}
            title={item.title}
            Icon={item.icon}
            link={item.link}
            select={
              submoduleAtual ===
              item.title
                .normalize('NFD')
                .replace(/[\u0300-\u036f\s]/g, '')
                .toLocaleLowerCase()
            }
          />
        ))}
      </DrawerMD>
      <Drawer
        sx={{
          display: {
            md: 'none',
          },
          '& .MuiDrawer-paper': {
            mt: 10,
            width: '100%',
            borderRadius: '20px',
          },
        }}
        variant="persistent"
        anchor="top"
        open={state.sidebarOpen}
      >
        {modulosPrivados.map((item, index) => (
          <ItemList
            key={index}
            open={state.sidebarOpen}
            title={item.title}
            Icon={item.icon}
            link={item.link}
            select={
              submoduleAtual ===
              item.title
                .normalize('NFD')
                .replace(/[\u0300-\u036f\s]/g, '')
                .toLocaleLowerCase()
            }
          />
        ))}
      </Drawer>

      <Main open={state.sidebarOpen}>
        <DrawerHeader />
        <Box
          sx={{
            p: {
              md: 4,
            },
            py: {
              xs: 2,
            },
          }}
        >
          <Outlet />
        </Box>
      </Main>
    </Box>
  );
}
