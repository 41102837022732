import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import { RoutePrivate, RouteProtect } from 'src/Auth/RouteProtect';
import MenuCards from 'src/pages/CardMenu';
import Login from 'src/pages/Login';
import LayoutSidebar from 'src/components/Layout';
import FundoNacionalMenu from 'src/pages/Planejamento/FundoNacional';
import PlanoAplicacao from 'src/pages/Planejamento/FundoNacional/PlanoAplicacao';
import CadastrarPlano from 'src/pages/Planejamento/FundoNacional/PlanoAplicacao/Cadastro';
import CadastrarMetasFisicas from 'src/pages/Planejamento/FundoNacional/Metas/Cadastro/MetaFisica';
import MetasPlano from 'src/pages/Planejamento/FundoNacional/Metas';
import { PageError } from 'src/pages/PageErro';
import Usuarios from 'src/pages/Admin/Usuarios';
import CadastroUsuario from 'src/pages/Admin/Usuarios/Cadastro';
import { Container, Typography } from '@mui/material';
import Dashboard from 'src/pages/Planejamento/Dashboard';
import Autorizacao from 'src/pages/Planejamento/FundoNacional/Autorizacao';
import PlanoAplicacaoVisualizarAutorizacao from 'src/pages/Planejamento/FundoNacional/Autorizacao/Visualizar/PlanoAplicacao';
import Remanejamento from 'src/pages/Planejamento/FundoNacional/Financeiro/Remanejamento';
import MetaGeralVisualizarAutorizacao from 'src/pages/Planejamento/FundoNacional/Autorizacao/Visualizar/MetaGeral';
import Unidades from 'src/pages/Admin/Unidades';
import Financeiro from 'src/pages/Planejamento/FundoNacional/Financeiro';
import AeronaveMenu from 'src/pages/Ciopaer/Aeronave';
import { Despesas } from 'src/pages/Admin/Despesas';
import Aeronaves from 'src/pages/Ciopaer/Aeronave/Aeronaves';
import Abastecimento from 'src/pages/Ciopaer/Aeronave/Abastecimento';
import Permissoes from 'src/pages/Admin/Permissoes';
import DespesasFundo from 'src/pages/Planejamento/FundoNacional/Despesas';
import CadastrarEmpenho from 'src/pages/Planejamento/FundoNacional/Despesas/Empenhos/Cadastrar';
import { Planejamento } from './Planejamento';
import { Admin } from './Admin';
import { Ciopaer } from './Ciopaer';

const router = createBrowserRouter([
  {
    path: '*',
    element: <PageError />,
  },
  {
    element: <Login />,
    path: '/',
  },
  {
    element: <RouteProtect element={<MenuCards />} />,
    path: '/menu',
  },

  // ADMIN
  Admin,
  // PLANEJAMENTO
  Planejamento,
  // CIOPAER
  Ciopaer,
]);

export default router;
