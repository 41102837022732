import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { Button, Grid } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import { useContext } from 'react';
import { AuthContext } from 'src/Context/ContextUser';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  boxShadow: 24,
  background: 'white',
  borderRadius: '10px',
  p: 4,
};

export default function ModalAutorizar({
  isOpen,
  nomeAcao,
  mutate,
  handleClose,
  id,
}: {
  isOpen: boolean;
  nomeAcao: string;
  id: string;
  mutate: ({
    id,
    usuarioAuthorizationId,
  }: {
    id: string;
    usuarioAuthorizationId: string | undefined;
  }) => void;
  handleClose: () => void;
}) {
  const { state } = useContext(AuthContext);
  const dadosAutorizacao = { id, usuarioAuthorizationId: state.usuario?.id };
  return (
    <div>
      <Modal open={isOpen}>
        <Box sx={style}>
          <Box sx={{ textAlign: 'center' }}>
            <WarningAmberIcon sx={{ fontSize: 40 }} color="warning" />
            <Typography variant="h6" sx={{ color: '#6D6C6C' }}>
              Deseja autorizar esse {nomeAcao}?
            </Typography>
          </Box>
          <Box sx={{ textAlign: 'center', my: 3 }}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item md={5}>
                <Button
                  variant="soft"
                  fullWidth
                  startIcon={<ClearIcon />}
                  onClick={handleClose}
                  color="error"
                >
                  NÃO
                </Button>
              </Grid>
              <Grid item md={5}>
                <Button
                  variant="soft"
                  color="success"
                  fullWidth
                  startIcon={<CheckIcon />}
                  onClick={() => {
                    mutate(dadosAutorizacao);
                    handleClose();
                  }}
                >
                  SIM
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
