export function tratandoValor(valor: number) {
  const resultado = valor * 100;
  const resultadoInteiro = Math.round(resultado);
  return resultadoInteiro;
}
export function valorMonetario(value: string | number) {
  // Converte o valor para número, caso seja string
  const numero = typeof value === 'string' ? parseFloat(value) : value;

  const valorFormatado = numero.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });

  return valorFormatado; // Retorna o valor formatado
}
export function CalculandoPorcentagem(valorGasto: number, valorInicial: number) {
  if (valorInicial === 0) {
    return 0.0;
  }

  const porcentagemGasta = (valorGasto / valorInicial) * 100;
  return parseFloat(porcentagemGasta.toFixed(2)); // Converte para número
}

export function tratandoValorCentavos(valor: number) {
  const valorNumerico = Number(valor) / 100;
  return new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(valorNumerico);
}
export function tratandoValorCentavosEditar(valor: number) {
  const valorNumerico = Number(valor) / 100;
  return valorNumerico;
}

export function tratandoData(Data: string) {
  const date = new Date(Data);
  return date.toLocaleDateString('pt-BR', { timeZone: 'UTC' });
}
export function tratandoDataInput(Data: string) {
  const dataFormatada = Data.split('T')[0];

  return dataFormatada;
}

//
export function SomaTotal(item: [], param: string, param1?: string) {
  let valorTotal = 0;
  item.forEach((valorInicial: any) => {
    if (param1) {
      valorTotal += valorInicial[param] + valorInicial[param1];
    } else {
      valorTotal += valorInicial[param];
    }
  });

  return valorTotal;
}

export function diferencaEmDias(dataInicial: string): number {
  const umDiaEmMilissegundos = 1000 * 60 * 60 * 24;
  const hoje = new Date();
  const data = new Date(dataInicial);
  const timeDiff = data.getTime() - hoje.getTime();
  const diffDays = Math.ceil(timeDiff / umDiaEmMilissegundos);

  return diffDays;
}

export const getColor = (dias: number) => {
  if (dias > 180) return 'success';
  if (dias < 0) return 'error';
  return 'warning';
};
