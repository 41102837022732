import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { URL_API, URL_API_EMPENHOS } from 'src/config/constants';

// MIENRVA
export const Api = axios.create({ baseURL: URL_API });

const user = localStorage.getItem('usuario');

if (user) {
  Api.interceptors.request.use((request) => {
    const userData = JSON.parse(user);
    if (userData.token) {
      request.headers.Authorization = `Bearer ${userData.token}`;
    }
    return request;
  });
}
Api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.removeItem('usuario');
        const navigate = useNavigate();
        navigate('/login');
        return Promise.reject(new Error('Token inválido. Redirecionando para o login.'));
      }
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);
export const ApiEmpenho = axios.create({ baseURL: URL_API_EMPENHOS });

if (user) {
  ApiEmpenho.interceptors.request.use((request) => {
    const userData = JSON.parse(user);
    if (userData.token) {
      request.headers.Authorization = `Bearer ${userData.token}`;
    }
    return request;
  });
}
