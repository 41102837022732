import { Box, Stack } from '@mui/material';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import { CustomContainerCad } from 'src/components/CustomContainer/custom-container';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErroResponse } from 'src/pages/types';
import FormsProvider from 'src/components/Forms/Provider';
import { useMutation } from '@tanstack/react-query';
import { ToastFun } from 'src/config/functions';
import { AuthContext } from 'src/Context/ContextUser';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { postDespesas } from 'src/services/Planejamento/FundoNacional/despesas';
import EmpenhoForms from '../Formulario';
import { DadosVinculadosEmpenho, defaultValuesEmpenhos, schemaEmpenhos } from '../Formulario/type';

export default function CadastrarEmpenho() {
  const navigate = useNavigate();

  const { state } = useContext(AuthContext);

  const methods = useForm({
    resolver: yupResolver(schemaEmpenhos),
    defaultValues: defaultValuesEmpenhos,
  });

  const {
    unregister,
    setValue,
    watch,
    formState: { errors },
  } = methods;

  const { mutate, isPending } = useMutation({
    mutationFn: postDespesas,
    onSuccess: (data) => {
      navigate('/planejamento/fundonacional/despesas/');
      ToastFun('Despesas Cadastradas com sucesso', 'success');
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  const onSubmit = async (data: DadosVinculadosEmpenho) => {
    data.usuarioCreatedId = state.usuario?.id!;
    data.Empenhos = data.Empenhos.filter((item) => item !== null);
    mutate(data);
  };

  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={5}>
        <CustomBreadcrumbs
          heading="Fundo Nacional de Segurança Pública"
          links={[
            { name: 'Planejamento', href: '/planejamento' },
            { name: 'Fundo Nacional', href: '/planejamento/fundonacional' },
            { name: 'Despesas', href: '/planejamento/fundonacional/despesas' },
            { name: 'Cadastro', href: '/planejamento/fundonacional/planodeaplicacao/cadastro' },
          ]}
        />
        <CustomContainerCad title="Empenhos">
          <FormsProvider methods={methods} handleSubmit={onSubmit}>
            <EmpenhoForms unregister={unregister} setValue={setValue} watch={watch} />
          </FormsProvider>
        </CustomContainerCad>
      </Stack>
    </Box>
  );
}
