import { Button, Grid, IconButton, MenuItem } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { FieldValues, UseFormSetValue, UseFormWatch, useWatch } from 'react-hook-form';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import DeleteIcon from '@mui/icons-material/Delete';
import TextFieldMoney from 'src/components/Forms/TextFieldMoney';
import { useEffect } from 'react';
import { getUnidadesInstituicao } from 'src/services/Admin/get';

export function InstituicaoBeneficiada({
  indexInstituicao,
  index,
  rowInstituicoes,
  instituicaoBeneficiada,

  setValue,
  excluirInstituicao,
}: {
  indexInstituicao: number;
  index: number;
  rowInstituicoes: any;
  instituicaoBeneficiada: any;
  setValue: UseFormSetValue<FieldValues>;
  excluirInstituicao: (indexInst: number) => void;
}) {
  const idInstituicao = useWatch({
    name: `metasFisicas[${index}].metasInstituicoes[${indexInstituicao}].instituicaoId`,
  });
  const instituicaoSelecionadas = useWatch({ name: `metasFisicas[${index}].metasInstituicoes` });
  const valorInicial = useWatch({
    name: `metasFisicas[${index}].metasInstituicoes[${indexInstituicao}].valorInicial`,
  });

  const { data: rowsUnidades } = useQuery({
    queryKey: ['getUnidadesInstituicao', idInstituicao],
    queryFn: getUnidadesInstituicao,
    enabled: !!idInstituicao,
  });
  useEffect(() => {
    if (valorInicial >= 0) {
      setValue(
        `metasFisicas[${index}].metasInstituicoes[${indexInstituicao}].saldoEstimativo`,
        valorInicial
      );
      setValue(
        `metasFisicas[${index}].metasInstituicoes[${indexInstituicao}].saldoReal`,
        valorInicial
      );
    }
  }, [index, indexInstituicao, setValue, valorInicial]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3.5}>
        <TextFieldRG
          select
          label="Instituição"
          name={`metasFisicas[${index}].metasInstituicoes[${indexInstituicao}].instituicaoId`}
        >
          {rowInstituicoes?.results.map((item: any) => (
            <MenuItem
              key={item.id}
              value={item.id}
              disabled={instituicaoSelecionadas?.some(
                (inst: any) => inst.instituicaoId === item.id
              )}
            >
              {item.sigla}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>
      {rowsUnidades?.length > 0 && (
        <Grid item xs={12} md={4}>
          <TextFieldRG
            select
            label="Unidade"
            name={`metasFisicas[${index}].metasInstituicoes[${indexInstituicao}].unidadeId`}
          >
            {rowsUnidades?.map((item: any) => (
              <MenuItem key={item.id} value={item.id}>
                {item.sigla}
              </MenuItem>
            ))}
          </TextFieldRG>
        </Grid>
      )}

      <Grid item xs={12} md={4}>
        <TextFieldMoney
          label="Valor Inicial"
          name={`metasFisicas[${index}].metasInstituicoes[${indexInstituicao}].valorInicial`}
        />
      </Grid>
      <Grid item xs={12} md={0.5} sx={{ textAlign: 'right', my: 1 }}>
        <IconButton
          aria-label="delete"
          disabled={instituicaoBeneficiada.length === 1}
          color="error"
          sx={{ my: 2 }}
          onClick={() => excluirInstituicao(indexInstituicao)}
        >
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}
