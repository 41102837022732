import TextField from '@mui/material/TextField';
import { Controller, useFormContext } from 'react-hook-form';
import { useMask } from '@react-input/mask';

interface TextFieldData {
  label: string;
  name: string;
  mask?: string;
  InputProps?: any;
  [key: string]: any;
}
export default function TextFieldRGMaks({ label, name, mask, InputProps, ...rest }: TextFieldData) {
  const inputRef = useMask({ mask: `${mask}`, replacement: { _: /\d/ } });
  const { register, control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { invalid, isTouched, isDirty, error },
        formState,
      }) => (
        <TextField
          sx={{ my: 2 }}
          label={label}
          variant="outlined"
          fullWidth
          inputRef={inputRef}
          InputProps={InputProps}
          value={value}
          {...rest}
          onChange={onChange}
          error={!!error}
          helperText={error?.message}
        />
      )}
    />
  );
}
