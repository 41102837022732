import { Box, Grid, styled, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { CalculandoPorcentagem, tratandoValorCentavos } from 'src/config/utils';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableContainerCustom from 'src/components/Table';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import { getMetaFisica } from 'src/services/Planejamento/FundoNacional/metas';
import { useEffect, useState } from 'react';
import { getDespesasMetaFisicaId } from 'src/services/Planejamento/FundoNacional/despesas';
import { GridColDef } from '@mui/x-data-grid';

const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: 700,
  color: '#555555',
  textAlign: 'center',
  textTransform: 'uppercase',
}));
const TableCellBody = styled(TableCell)(({ theme }) => ({
  fontSize: '15px',
  color: '#555555',
  textAlign: 'center',
  textTransform: 'uppercase',
}));

export default function DespesasVisualizaDetalhes({ id }: { id: string }) {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });

  const skip = paginationModel.page * paginationModel.pageSize;
  const take = paginationModel.pageSize;

  const include = ['metaFisicaCompleto', 'metasInstituicoes'];

  const [valores, setValores] = useState({
    valorTotalInicial: 0,
    valorTotalEmpenhado: 0,
    valorTotalExecutado: 0,
    valorTotalSaldoEstimativo: 0,
    valorTotalSaldoReal: 0,
  });

  const { data: rowsMeta, isPending } = useQuery({
    queryKey: ['getMetaFisica', id, include],
    queryFn: () => getMetaFisica(id, include),
  });
  const { data: rowsEmpenhos } = useQuery({
    queryKey: ['getDespesasMetaInstituicaoId', skip, take, id],
    queryFn: getDespesasMetaFisicaId,
  });

  useEffect(() => {
    if (rowsMeta) {
      const novosValores = rowsMeta.metasInstituicoes.reduce(
        (acc: any, item: any) => ({
          valorTotalInicial: acc.valorTotalInicial + item.valorInicial,
          valorTotalEmpenhado: acc.valorTotalEmpenhado + item.valorEmpenhado,
          valorTotalExecutado: acc.valorTotalExecutado + item.valorExecutado,
          valorTotalSaldoEstimativo: acc.valorTotalSaldoEstimativo + item.saldoEstimativo,
          valorTotalSaldoReal: acc.valorTotalSaldoReal + item.saldoReal,
        }),
        {
          valorTotalInicial: 0,
          valorTotalEmpenhado: 0,
          valorTotalExecutado: 0,
          valorTotalSaldoEstimativo: 0,
          valorTotalSaldoReal: 0,
        }
      );

      setValores(novosValores);
    }
  }, [rowsMeta]);

  if (isPending) {
    return <ModalCarregamento isOpen={isPending} />;
  }

  const columns: GridColDef[] = [
    {
      field: 'instituicao',
      headerName: 'INSTITUIÇÃO',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      renderCell: (params) => (
        <>
          {' '}
          {params.row.instituicao} {params.row.unidade && `  -  ${params.row.unidade}`}
        </>
      ),
    },
    {
      field: 'numeroEmpenho',
      headerName: 'Nº EMPENHO',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
    },
    {
      field: 'valorEmpenho',
      headerName: 'VALOR EMPENHADO',
      align: 'center',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => <>R$ {tratandoValorCentavos(params.row.valorEmpenho)}</>,
    },
    {
      field: 'valorComplemento',
      headerName: 'VALOR COMPLEMENTAR',
      align: 'center',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => <>R$ {tratandoValorCentavos(params.row.valorComplemento)}</>,
    },
    {
      field: 'valorAnulado',
      headerName: 'VALOR ANULADO',
      align: 'center',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => <>R$ {tratandoValorCentavos(params.row.valorAnulado)}</>,
    },
    {
      field: 'valorPago',
      headerName: 'VALOR PAGO',
      align: 'center',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => <>R$ {tratandoValorCentavos(params.row.valorPago)}</>,
    },
    {
      field: 'valorInicial',
      headerName: 'VALOR DISPONÍVEL',
      align: 'center',
      minWidth: 150,
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => (
        <>
          R${' '}
          {tratandoValorCentavos(
            params.row.valorEmpenho +
              params.row.valorComplemento -
              params.row.valorAnulado -
              params.row.valorPago
          )}
        </>
      ),
    },
  ];

  return (
    <Box
      sx={{
        background: 'white',
        borderRadius: '15px',
        p: 2,
        overflow: 'auto',
        scrollbarWidth: 'none',
        margin: 'auto',
        mt: 1,
      }}
    >
      <Grid container>
        <Grid md={12} xs={12}>
          <Box
            px={1}
            my={1}
            sx={{
              background: '#eef7f1',
              borderRadius: '5px',
              px: 1,
              color: 'green',
              textAlign: 'center',
            }}
          >
            <Typography variant="subtitle2" sx={{ py: 1 }}>
              VISÃO GERAL
            </Typography>
          </Box>
        </Grid>
        <TableContainer>
          <Table sx={{ minWidth: 700 }} aria-label="spanning table">
            <TableHead>
              <TableRow>
                <TableCellHead>Valor Total Inicial</TableCellHead>
                <TableCellHead>Valor Total Empenhado </TableCellHead>
                <TableCellHead>Valor Total Executado </TableCellHead>
                <TableCellHead>Valor Saldo Estimativo </TableCellHead>
                <TableCellHead>Valor Saldo Real </TableCellHead>
                <TableCellHead>% Execução </TableCellHead>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCellBody>R$ {tratandoValorCentavos(valores.valorTotalInicial)}</TableCellBody>
                <TableCellBody>
                  R$ {tratandoValorCentavos(valores.valorTotalEmpenhado)}
                </TableCellBody>
                <TableCellBody>
                  R$ {tratandoValorCentavos(valores.valorTotalExecutado)}
                </TableCellBody>
                <TableCellBody>
                  R$ {tratandoValorCentavos(valores.valorTotalSaldoEstimativo)}
                </TableCellBody>
                <TableCellBody>
                  R$ {tratandoValorCentavos(valores.valorTotalSaldoReal)}
                </TableCellBody>
                <TableCellBody>
                  {CalculandoPorcentagem(
                    valores.valorTotalExecutado,
                    valores.valorTotalExecutado + valores.valorTotalSaldoReal
                  )}
                  %
                </TableCellBody>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>

      <Grid container>
        <Grid md={12} xs={12}>
          <Box
            px={1}
            my={1}
            sx={{
              background: '#eef7f1',
              borderRadius: '5px',
              px: 1,
              color: 'green',
              textAlign: 'center',
            }}
          >
            <Typography variant="subtitle2" sx={{ py: 1 }}>
              DETALHAMENTO
            </Typography>
          </Box>
        </Grid>

        <Grid md={12} xs={12}>
          <TableContainer>
            <Table sx={{ minWidth: 700, mt: 2 }} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <TableCellHead>INSTITUIÇÃO</TableCellHead>
                  <TableCellHead>VALOR INICIAL</TableCellHead>
                  <TableCellHead>
                    VALOR REMANEJADO <br /> RECEBIDO
                  </TableCellHead>
                  <TableCellHead>
                    VALOR REMANEJADO <br /> RETIRADO
                  </TableCellHead>
                  <TableCellHead>RENDIMENTO ALOCADO</TableCellHead>
                  <TableCellHead>VALOR EMPENHADO</TableCellHead>
                  <TableCellHead>VALOR EXECUTADO</TableCellHead>
                  <TableCellHead>SALDO REAL</TableCellHead>
                  <TableCellHead>% Execução</TableCellHead>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsMeta.metasInstituicoes.map((item: any, index: number) => (
                  <TableRow key={index}>
                    <TableCellBody>{item.sigla}</TableCellBody>
                    <TableCellBody>R$ {tratandoValorCentavos(item.valorInicial)}</TableCellBody>
                    <TableCellBody>R$ {tratandoValorCentavos(item.saldoRecebido)}</TableCellBody>
                    <TableCellBody>R$ {tratandoValorCentavos(item.saldoRetirado)}</TableCellBody>
                    <TableCellBody>
                      R$ {tratandoValorCentavos(item.saldoRecebidoRendimentos)}
                    </TableCellBody>
                    <TableCellBody>R$ {tratandoValorCentavos(item.valorEmpenhado)}</TableCellBody>
                    <TableCellBody>R$ {tratandoValorCentavos(item.valorExecutado)}</TableCellBody>
                    <TableCellBody>R$ {tratandoValorCentavos(item.saldoReal)}</TableCellBody>
                    <TableCellBody>
                      {CalculandoPorcentagem(
                        item.valorExecutado,
                        item.valorExecutado + item.saldoReal
                      )}
                      %
                    </TableCellBody>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <Grid container>
        <Grid md={12} xs={12}>
          <Box
            px={1}
            my={1}
            sx={{
              background: '#eef7f1',
              borderRadius: '5px',
              px: 1,
              color: 'green',
              textAlign: 'center',
            }}
          >
            <Typography variant="subtitle2" sx={{ py: 1 }}>
              EMPENHOS E PAGAMENTOS
            </Typography>
          </Box>
          {rowsEmpenhos && (
            <TableContainerCustom
              columns={columns}
              rows={rowsEmpenhos.results}
              rowCount={rowsEmpenhos.total}
              paginationModel={paginationModel}
              onPaginationModelChange={setPaginationModel}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}
