import * as yup from 'yup';

export const schemaLogin = yup.object<DataLogin>({
  email: yup.string().required('Campo obrigatório'),
  senha: yup.string().required('Senha é obrigatório'),
});

export const defaultValuesPlano = {
  email: '',
  senha: '',
};

export interface DataLogin {
  email: string;
  senha: string;
}
