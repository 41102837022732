import { RoutePrivate } from 'src/Auth/RouteProtect';
import LayoutSidebar from 'src/components/Layout';
import AeronaveMenu from 'src/pages/Ciopaer/Aeronave';
import Abastecimento from 'src/pages/Ciopaer/Aeronave/Abastecimento';
import Aeronaves from 'src/pages/Ciopaer/Aeronave/Aeronaves';

export const Ciopaer =
  // CIOPAER
  {
    element: <RoutePrivate element={<LayoutSidebar />} permission="CIOPAER" />,
    path: 'ciopaer',
    children: [
      {
        element: <RoutePrivate element={<AeronaveMenu />} permission="CIOPAER" />,
        path: 'aeronaves',
      },
      {
        element: <RoutePrivate element={<Aeronaves />} permission="CIOPAER" />,
        path: 'aeronaves/list',
      },
      {
        element: <RoutePrivate element={<Abastecimento />} permission="CIOPAER" />,
        path: 'aeronaves/abastecimento',
      },
    ],
  };
