import { tratandoValor } from 'src/config/utils';
import * as yup from 'yup';

export const schemaRendimento = yup.object({
  planoAplicacaoId: yup.string().required('Exercicio Orçamentário é Obrigatório'),
  eixoFinanciadoId: yup.string().required('Eixo é Obrigatório'),
  conta: yup.string().required('é Obrigatório'),
  dataReferencia: yup.date().required('é Obrigatório'),
  valor: yup
    .mixed()
    .transform((value: any) => tratandoValor(value))
    .required('é Obrigatório'),
});
export interface FormValuesRendimento {
  valor?: string | number;
  dataReferencia: Date;
  planoAplicacaoId: string;
  eixoFinanciadoId: string;
  conta: string;
}
