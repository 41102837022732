import { Box, Modal, Grid, IconButton, Alert, MenuItem, Button } from '@mui/material';
import { useForm } from 'react-hook-form';
import { AuthContext } from 'src/Context/ContextUser';
import { useContext, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DrawIcon from '@mui/icons-material/Draw';
import { useMutation, useQuery } from '@tanstack/react-query';
import FormsProvider from 'src/components/Forms/Provider';
import { yupResolver } from '@hookform/resolvers/yup';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import SaveIcon from '@mui/icons-material/Save';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import AeronaveForms from '../Formulario/aeronave';

export default function CadastroAeronave({
  open,
  handleClose,
}: {
  open: boolean;
  handleClose: any;
}) {
  const methods = useForm({
    // resolver: yupResolver(schemaMetaGeral),
  });

  const { state } = useContext(AuthContext);

  //   const { mutate, isPending } = useMutation({
  //     mutationFn: postCadastrarMetaGeral,
  //     onSuccess: (data) => {
  //       ToastFun('Meta Cadastrada com sucesso', 'success');
  //       handleClose();
  //       RefecthMetas();
  //     },
  //     onError: (error: ErroResponse) => {
  //       ToastFun(error.response.data?.message, 'error');
  //     },
  //   });

  //   if (isPending) {
  //     return <ModalCarregamento isOpen={isPending} />;
  //   }
  const onSubmit = async (data: any) => {
    // data.MetaGeral.usuarioCreateId = state.usuario?.id;
    // mutate(data);
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          background: 'white',
          borderRadius: '15px',
          p: 5,
          width: {
            xl: '58%',
            md: '75%',
            sm: '70%',
          },
          maxHeight: '95%',
          overflow: 'auto',
          scrollbarWidth: 'none',
          margin: 'auto',
          mt: 3,
        }}
      >
        <Grid container>
          <Grid md={12} xs={12} sx={{ textAlign: 'right' }}>
            <IconButton aria-label="delete" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid md={12} xs={12}>
            <Alert
              sx={{ background: '#019362', color: 'white', fontWeight: '700' }}
              icon={<DrawIcon sx={{ color: 'white' }} />}
            >
              Aeronave - Cadastro
            </Alert>
            <FormsProvider methods={methods} handleSubmit={onSubmit}>
              <AeronaveForms />
            </FormsProvider>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
