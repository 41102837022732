import { InputAdornment } from '@mui/material';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Controller, useFormContext } from 'react-hook-form';
import { NumericFormat } from 'react-number-format';

interface TextFieldData {
  label: string;
  name: string;
  inputRef?: any;
  [key: string]: any;
}

export default function TextFieldMoney({ label, name, inputRef, ...rest }: TextFieldData) {
  const { control } = useFormContext();

  return (
    <Controller
      control={control}
      name={name}
      render={({
        field: { onChange, onBlur, value },
        fieldState: { invalid, isTouched, isDirty, error },
      }) => (
        <NumericFormat
          value={value}
          onValueChange={(values) => {
            onChange(values.floatValue === undefined ? null : values.floatValue);
          }}
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={2}
          fixedDecimalScale
          prefix="R$ "
          customInput={TextField}
          label={label}
          variant="outlined"
          fullWidth
          sx={{ my: 2 }}
          error={!!error}
          helperText={error?.message}
          inputRef={inputRef}
          {...rest}
        />
      )}
    />
  );
}
