import * as yup from 'yup';

interface Permissoes {
  funcao: string;
  modulo: string;
}

export interface dadosUsuario {
  nome: string;
  email: string;
  senha: string;
  permissoes: Permissoes[];
  permissoesUsuario: object[];
}

export const schemaUsuario = yup.object<dadosUsuario>({
  nome: yup.string().required('Nome é obrigatório'),
  email: yup.string().required('E-mail é obrigatório'),
  cpf: yup
    .mixed()
    .required('CPF é obrigatório')
    .transform((value: any) => {
      const limpandoString = value.replace(/[.-]/g, '');
      return limpandoString;
    }),
  senha: yup.string().required('Senha é obrigatório'),
  permissoes: yup.array(),
});

export const defaultValuesUsuario = {
  nome: '',
  email: '',
  senha: '',
  permissoes: [],
};

type ModulosData = {
  [key: string]: string[];
};
export const Mod = [
  'ADMINISTRAÇÃO',
  'CIAB',
  'FINANCEIRO',
  'PLANEJAMENTO',
  'TIC',
  'CIOPAER',
  'RH',
  'OPERACIONAL',
];
export const modulosData: ModulosData = {
  diretor: ['ADMINISTRAÇÃO', 'PLANEJAMENTO', 'TIC', 'OPERACIONAL'],
  coordenador: ['CIAB', 'CIOPAER'],
  assistente: [
    'ADMINISTRAÇÃO',
    'CIAB',
    'FINANCEIRO',
    'PLANEJAMENTO',
    'TIC',
    'CIOPAER',
    'RH',
    'OPERACIONAL',
  ],
  gestor: [
    'ADMINISTRAÇÃO',
    'CIAB',
    'FINANCEIRO',
    'CIOPAER',
    'PLANEJAMENTO',
    'TIC',
    'RH',
    'OPERACIONAL',
  ],
  monitor: [
    'ADMINISTRAÇÃO',
    'CIAB',
    'FINANCEIRO',
    'CIOPAER',
    'PLANEJAMENTO',
    'TIC',
    'RH',
    'OPERACIONAL',
  ],
};
export const funcoesData = [
  'ADMINISTRADOR',
  'ASSISTENTE',
  'COORDENADOR',
  'DIRETOR',
  'GESTOR',
  'MONITOR',
];
