import { useState, useEffect } from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SaveIcon from '@mui/icons-material/Save';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import { Grid, Typography, Button, Box, TextField, IconButton, Alert } from '@mui/material';
import FormsProvider from 'src/components/Forms/Provider';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { AtualizarUsuario, InserirPermissaoUsuario } from 'src/services/Admin/patch';
import { ExcluirPermissaoUsuario } from 'src/services/Admin/delete';
import { useMutation } from '@tanstack/react-query';
import { ToastFun } from 'src/config/functions';
import CachedIcon from '@mui/icons-material/Cached';
import { ErroResponse } from 'src/pages/types';
import TextFieldRGMaks from 'src/components/Forms/TextFieldRG/TextFieldMask';
import { funcoesData, modulosData, schemaUsuario } from '../typeUsuario';

export function FormularioAtualizarUsuario({
  dadosUsuario,
  refetch,
  handleClose,
}: {
  dadosUsuario: any;
  refetch: any;
  handleClose: any;
}) {
  // CASO TENHA NOVAS FUNÇÕES
  const [funcao, setFuncao] = useState<string>();
  const [modulo, setModulo] = useState<string>();
  const [novaPermissao, setNovaPermissao] = useState(false);
  const [messageErro, setMessageErro] = useState<string>();

  const methods = useForm({
    defaultValues: dadosUsuario.usuario,
  });
  const { reset, handleSubmit, watch, setValue } = methods;

  // PEGANDO NOVA FUNÇÃO/MÓDULO
  const handleChange = (event: SelectChangeEvent) => {
    const {
      target: { name, value },
    } = event;
    if (name === 'funcao' && value === 'administrador') {
      setModulo('admin');
    }
    if (name === 'funcao') {
      setFuncao(value);
    } else {
      setModulo(value);
    }
  };

  // ATUALIZAR USUÁRIO
  const { mutate, isPending } = useMutation({
    mutationFn: AtualizarUsuario,
    onSuccess: (data) => {
      setMessageErro(undefined);
      handleClose();
      ToastFun('Usuário Atualizado com Sucesso', 'success');
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });

  // EXCLUIR PERMISSOES EXISTENTE
  const { mutate: excluirPermissaoUsuario } = useMutation({
    mutationFn: ExcluirPermissaoUsuario,
    onSuccess: (data) => {
      setMessageErro(undefined);
      setNovaPermissao(false);
      refetch();
    },
    onError: (error: ErroResponse) => {
      setMessageErro(error.response.data?.message);
    },
  });
  function excluirPermissao(funcaoUsuario: string, moduloUsuario: string) {
    if (funcaoUsuario === 'admin') {
      moduloUsuario = 'admin';
    }

    const excluirpermissao = {
      id: dadosUsuario.usuario.id,
      permissao: [
        { nome: funcaoUsuario === 'admin' ? funcaoUsuario : `${funcaoUsuario}_${moduloUsuario}` },
      ],
    };
    excluirPermissaoUsuario(excluirpermissao);
  }

  // INSERIR PERMISSOES EXISTENTE
  const { mutate: inserirPermissaoUsuario } = useMutation({
    mutationFn: InserirPermissaoUsuario,
    onSuccess: (data) => {
      setMessageErro(undefined);
      setModulo('');
      setFuncao('');
      setNovaPermissao(false);
      refetch();
    },
    onError: (error: ErroResponse) => {
      setMessageErro(error.response.data?.message);
    },
  });

  function inserirPermissao(moduloUsuario: string) {
    const novapermissao = {
      id: dadosUsuario.usuario.id,
      permissao: [{ nome: moduloUsuario }],
    };
    inserirPermissaoUsuario(novapermissao);
  }

  const onSubmit = async (data: any) => {
    mutate(data);
  };
  return (
    <FormsProvider methods={methods} handleSubmit={onSubmit}>
      <Grid container spacing={2} sx={{ color: '#555555' }}>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2">Email</Typography>
          <TextFieldRG type="text" name="email" label="" />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2">Nome</Typography>
          <TextFieldRG type="text" name="nome" label="" />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2">CPF</Typography>
          <TextFieldRGMaks label="" name="cpf" mask="___.___.___-__" />
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography variant="subtitle2">Status</Typography>
          <TextFieldRG select name="status" label="">
            <MenuItem value="Ativo">Ativo</MenuItem>
            <MenuItem value="Inativo">Inativo</MenuItem>
          </TextFieldRG>
        </Grid>
        <Grid item xs={12} md={9}>
          <Typography variant="subtitle2">Permissões</Typography>
        </Grid>
        {dadosUsuario.permissoes[0]?.funcao !== 'admin' && (
          <Grid item xs={12} md={3} sx={{ textAlign: 'right' }}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={() => setNovaPermissao(true)}
            >
              Nova Permissão
            </Button>
          </Grid>
        )}

        <Grid item xs={12} md={12}>
          {novaPermissao && (
            <Grid container>
              <Grid item xs={5} md={5}>
                <Select
                  sx={{ my: 2 }}
                  fullWidth
                  name="funcao"
                  value={funcao}
                  onChange={handleChange}
                >
                  {funcoesData.map((item, index) => (
                    <MenuItem
                      key={index}
                      value={item
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f\s]/g, '')
                        .toLocaleLowerCase()}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              {funcao && funcao !== 'administrador' && (
                <Grid item xs={5} md={5} sx={{ pl: 1 }}>
                  <Select
                    fullWidth
                    name="modulo"
                    value={modulo}
                    onChange={handleChange}
                    sx={{ my: 2 }}
                  >
                    {modulosData[funcao].map((name: string) => (
                      <MenuItem
                        key={name}
                        value={`${funcao}_${name
                          .normalize('NFD')
                          .replace(/[\u0300-\u036f\s]/g, '')
                          .toLocaleLowerCase()}`}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}
              {modulo && (
                <Grid
                  item
                  xs={2}
                  md={funcao === 'administrador' ? 7 : 2}
                  sx={{ textAlign: 'right', my: 2, p: 1 }}
                >
                  <Button
                    variant="contained"
                    color="success"
                    startIcon={<SaveIcon />}
                    onClick={() => inserirPermissao(modulo)}
                  >
                    Salvar
                  </Button>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} md={12}>
          <Grid container>
            {dadosUsuario.permissoes.map((item: any, index: number) => (
              <>
                <Grid item xs={5} md={5}>
                  <TextField
                    fullWidth
                    sx={{ my: 1 }}
                    disabled
                    value={item.funcao === 'admin' ? 'Administrador' : item.funcao}
                  />
                </Grid>
                {item.modulo && (
                  <Grid item xs={5} md={5}>
                    <TextField fullWidth sx={{ m: 1 }} disabled value={item.modulo} />
                  </Grid>
                )}

                <Grid
                  item
                  xs={item.modulo ? 2 : 7}
                  md={item.modulo ? 2 : 7}
                  sx={{ textAlign: 'right' }}
                >
                  <IconButton
                    aria-label="delete"
                    color="error"
                    sx={{ my: 2 }}
                    onClick={() => excluirPermissao(item.funcao, item.modulo)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </>
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12} md={12}>
          {messageErro && <Alert severity="warning">{messageErro}</Alert>}
        </Grid>
        <Grid item xs={12} md={12} sx={{ textAlign: 'right', px: 1 }}>
          <Button
            variant="contained"
            sx={{ px: 3 }}
            color="success"
            type="submit"
            startIcon={<CachedIcon />}
          >
            Atualizar
          </Button>
        </Grid>
      </Grid>
    </FormsProvider>
  );
}
