import { useEffect, useState } from 'react';
import {
  Grid,
  Button,
  Select,
  MenuItem,
  Typography,
  ListItemText,
  Checkbox,
  SelectChangeEvent,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
// import { getEixos, ToastFun } from 'src/config/functions';
import { useQuery } from '@tanstack/react-query';
import { getFlagsPermissoes } from 'src/services/Admin/get';
import { useWatch } from 'react-hook-form';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function GrupoForms({
  setValue,
  permissoes,
}: {
  setValue: any;
  permissoes?: string[];
}) {
  const [flags, setflags] = useState<string[]>([]);
  const handleChange = (event: SelectChangeEvent<typeof flags>) => {
    const {
      target: { value },
    } = event;
    setflags(typeof value === 'string' ? value.split(',') : value);
  };

  const permissao = useWatch({ name: 'permissoes' });

  // BUSCAR EM CASO DE EDIÇÃO
  useEffect(() => {
    if (permissoes) {
      setflags(permissoes);
    }
  }, [permissoes]);

  // PEGAR AS FLAGS SELECIONADAS
  useEffect(() => {
    if (flags) {
      setValue('permissoes', flags);
    }
  }, [flags, setValue]);

  const { data: rows } = useQuery({
    queryKey: ['get'],
    queryFn: getFlagsPermissoes,
  });

  return (
    <Grid container spacing={2} justifyContent="center" my={2}>
      <Grid item xs={12} md={12} lg={6}>
        <Typography variant="subtitle2">Nome</Typography>
        <TextFieldRG label="" name="nome" />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
        <Typography variant="subtitle2">Permissões</Typography>

        <Select
          fullWidth
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={flags}
          onChange={handleChange}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
          sx={{ my: 2 }}
        >
          {rows?.map((item: string) => (
            <MenuItem key={item} value={item}>
              <Checkbox checked={flags.includes(item)} />
              <ListItemText primary={item} />
            </MenuItem>
          ))}
        </Select>
      </Grid>

      {permissoes && (
        <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
          <Button variant="contained" color="success" type="submit" startIcon={<SaveIcon />}>
            Atualizar
          </Button>
        </Grid>
      )}
      {!permissoes && (
        <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
          <Button variant="contained" color="success" type="submit" startIcon={<SaveIcon />}>
            Cadastrar
          </Button>
        </Grid>
      )}
    </Grid>
  );
}
