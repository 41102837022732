import { Alert, Grid, IconButton, Modal } from '@mui/material';
import Box from '@mui/material/Box';
import { useContext, useEffect } from 'react';
import FormsProvider from 'src/components/Forms/Provider';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import DrawIcon from '@mui/icons-material/Draw';
import { AuthContext } from 'src/Context/ContextUser';
import { useMutation, useQuery } from '@tanstack/react-query';
import { ToastFun } from 'src/config/functions';
import { ErroResponse } from 'src/pages/types';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import { postCadastrarRendimento } from 'src/services/Planejamento/FundoNacional/rendimento';
import FormularioRendimento from '../Formulario/Rendimento';
import { schemaRendimento } from '../Formulario/type';

export default function CadastrarRendimento({
  RefecthRendimentos,
  open,
  handleClose,
  planoAplicacao,
}: {
  RefecthRendimentos: any;
  open: boolean;
  handleClose: any;
  planoAplicacao: string;
}) {
  const methods = useForm({
    resolver: yupResolver(schemaRendimento),
  });
  const { setValue } = methods;
  const { state } = useContext(AuthContext);

  useEffect(() => {
    if (planoAplicacao) {
      setValue('planoAplicacaoId', planoAplicacao);
    }
  }, [planoAplicacao, setValue]);

  const { mutate, isPending } = useMutation({
    mutationFn: postCadastrarRendimento,
    onSuccess: (data) => {
      ToastFun('Rendimento cadastrado com sucesso!', 'success');
      handleClose();
      RefecthRendimentos();
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });
  if (isPending) {
    return <ModalCarregamento isOpen={isPending} />;
  }
  const onSubmit = async (data: any) => {
    data.usuarioCreatedId = state.usuario?.id;
    mutate(data);
  };
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          background: 'white',
          borderRadius: '15px',
          p: 5,
          width: {
            xl: '58%',
            md: '75%',
            sm: '70%',
          },
          maxHeight: '95%',
          overflow: 'auto',
          scrollbarWidth: 'none',
          margin: 'auto',
          mt: 3,
        }}
      >
        <Grid container>
          <Grid md={12} xs={12} sx={{ textAlign: 'right' }}>
            <IconButton aria-label="delete" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Grid md={12} xs={12}>
            <Alert
              sx={{ background: '#019362', color: 'white', fontWeight: '700' }}
              icon={<DrawIcon sx={{ color: 'white' }} />}
            >
              Rendimento - Cadastro
            </Alert>
            <Box sx={{ my: 1 }}>
              <FormsProvider methods={methods} handleSubmit={onSubmit}>
                <FormularioRendimento setValue={setValue} />
              </FormsProvider>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
