import { Box, Button, Card, CardContent, Grid, MenuItem, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import { FieldValues, UseFormSetValue, UseFormUnregister, useWatch } from 'react-hook-form';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import SaveIcon from '@mui/icons-material/Save';
import TextFieldRGMaks from 'src/components/Forms/TextFieldRG/TextFieldMask';
import { getPlanoAplicacao } from 'src/services/Planejamento/FundoNacional/planoAplicacao';
import { getEixosFinanciados } from 'src/services/Planejamento/FundoNacional/eixoFinanciado';
import { getMetasFisicas } from 'src/services/Planejamento/FundoNacional/metas';
import { tratandoValorCentavos } from 'src/config/utils';
import { ToastFun } from 'src/config/functions';
import BuscarEmpenho from './empenho';

export default function EmpenhoForms({
  unregister,
  setValue,
  watch,
}: {
  setValue: UseFormSetValue<FieldValues>;
  unregister: UseFormUnregister<FieldValues>;
  watch: any;
}) {
  const [instituicoesAlocacao, setInstituicoesAlocacao] = useState([]);
  const [empenhos, setEmpenhos] = useState<number[]>([0]);
  const [valorDisponivel, setValorDisponivel] = useState();

  const novoEmpenho = () => {
    const novoEmpenhos = [...empenhos];
    const count = novoEmpenhos[novoEmpenhos.length - 1] + 1;
    setEmpenhos([...novoEmpenhos, count]);
  };

  const planoAplicacaoId = useWatch({ name: 'planoAplicacaoId' });
  const eixoFinanciadoId = useWatch({ name: 'eixoFinanciadoId' });
  const natureza = useWatch({ name: 'natureza' });
  const metaInstituicaoId = useWatch({ name: 'metaInstituicaoId' });
  const metaFisicaId = useWatch({ name: 'metaFisicaId' });
  const Empenhos = useWatch({ name: 'Empenhos' });

  const { data: planoAplicacao } = useQuery({
    queryKey: ['getPlanoAplicacao'],
    queryFn: getPlanoAplicacao,
  });

  const { data: eixosFinanciados } = useQuery({
    queryKey: ['getEixosFinanciados', planoAplicacaoId],
    queryFn: getEixosFinanciados,
    enabled: !!planoAplicacaoId,
  });

  const include = ['metaInstituicaoCount', 'metaGeral', 'metasInstituicoes'];

  const buscarMetas = {
    include,
    planoAplicacaoId,
    eixoFinanciadoId,
    natureza,
  };

  // BUSCAR METAS FISICA
  const { data: metasFisicas } = useQuery<{ total: number; results: object[] }>({
    queryKey: ['getMetaFisica', planoAplicacaoId, eixoFinanciadoId, natureza],
    queryFn: () => getMetasFisicas(buscarMetas),
    enabled: !!planoAplicacaoId && !!eixoFinanciadoId && !!natureza,
  });

  useEffect(() => {
    if (metaFisicaId) {
      const metaAlocacao: any = metasFisicas?.results.filter(
        (item: any) => item.id === metaFisicaId
      );
      if (metaAlocacao && metaAlocacao.length > 0) {
        setInstituicoesAlocacao(metaAlocacao[0].metasInstituicoes);
      } else {
        setInstituicoesAlocacao([]);
      }
    }
  }, [metaFisicaId, metasFisicas?.results]);

  useEffect(() => {
    if (instituicoesAlocacao && metaInstituicaoId) {
      const foundItem: any = instituicoesAlocacao.find(
        (item: any) => item.id === metaInstituicaoId
      );
      const saldoEstimativo = foundItem ? foundItem.saldoEstimativo : undefined;
      setValorDisponivel(saldoEstimativo);
    }
  }, [instituicoesAlocacao, metaInstituicaoId]);

  const ExcluirEmpenho = (index: number) => {
    if (empenhos.length > 1) {
      unregister(`Empenhos[${index}]`);
      setEmpenhos((prevEmpenhos) =>
        prevEmpenhos.filter((empenhoVinculado) => empenhoVinculado !== index)
      );
    } else {
      ToastFun('É necessário vincular ao menos um empenho', 'error');
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={12} lg={2}>
        <TextFieldRG select name="planoAplicacaoId" label="Exercício Orçamentário">
          {planoAplicacao?.results.map((item: any, indexExercicio: number) => (
            <MenuItem key={indexExercicio} value={item.id} disabled={item.status === 'Pendente'}>
              {item.exercicioOrcamentario}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={2}>
        <TextFieldRG select name="natureza" label="Natureza">
          <MenuItem value="investimento">Investimento</MenuItem>
          <MenuItem value="custeio">Custeio</MenuItem>
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <Box display="grid">
          <TextFieldRG select name="eixoFinanciadoId" label="Eixo/Área Temática">
            {eixosFinanciados?.map((item: any, indexEixoFinanciado: number) => (
              <MenuItem key={indexEixoFinanciado} value={item.id}>
                {item.eixoNome}
              </MenuItem>
            ))}
          </TextFieldRG>
        </Box>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <TextFieldRGMaks
          mask="____.______._____/____-__"
          label="Processo de Contratação - SEI"
          name="processoSeiMatriz"
        />
      </Grid>
      <Grid item xs={12} md={12} lg={8}>
        <Box display="grid">
          <TextFieldRG select name="metaFisicaId" label="Meta Física">
            {metasFisicas?.results.map((item: any) => (
              <MenuItem key={item.id} value={item.id}>
                {item.nome}
              </MenuItem>
            ))}
          </TextFieldRG>
        </Box>
      </Grid>
      <Grid item xs={12} md={12} lg={4}>
        <TextFieldRG select name="metaInstituicaoId" label="Instituição">
          {instituicoesAlocacao.map((item: any) => (
            <MenuItem key={item.id} value={item.id}>
              {item.sigla}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>
      <Grid
        item
        sm={12}
        md={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        {(valorDisponivel || valorDisponivel === 0) && (
          <Card sx={{ background: '#E8F7FF', color: '#4CC0FF', width: '200px' }}>
            <CardContent>
              <Typography sx={{ fontSize: 13, fontWeight: 600 }} gutterBottom>
                Valor Disponível
              </Typography>
              <Typography sx={{ fontSize: 13, fontWeight: 600 }}>
                R$ {tratandoValorCentavos(valorDisponivel)}
              </Typography>
            </CardContent>
          </Card>
        )}
      </Grid>
      <Grid item md={12} xs={12} sx={{ textAlign: 'right' }}>
        <Button
          sx={{ mt: 3 }}
          color="primary"
          variant="contained"
          startIcon={<AddIcon />}
          onClick={novoEmpenho}
        >
          Novo Empenho
        </Button>
      </Grid>

      {empenhos.map((item, index) => (
        <BuscarEmpenho
          key={item}
          item={item}
          index={index}
          setValue={setValue}
          ExcluirEmpenho={ExcluirEmpenho}
          metaInstituicaoId={metaInstituicaoId}
          watch={watch}
        />
      ))}
      <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
        <Button variant="contained" color="success" type="submit" startIcon={<SaveIcon />}>
          Cadastrar
        </Button>
      </Grid>
    </Grid>
  );
}
