import { tratandoValor } from 'src/config/utils';
import * as yup from 'yup';

export const schemaRendimentoAlocacao = yup.object<RendimentoALocacao>({
  planoAplicacaoId: yup.string().required('Exercicio Orçamentário é Obrigatório'),
  eixoFinanciadoId: yup.string().required('Eixo é Obrigatório'),
  rendimentoContaId: yup.string(),
  conta: yup.string().required('é Obrigatório'),
  metaFisicaId: yup.string().required('é Obrigatório'),
  metaInstituicaoId: yup.string().required('é Obrigatório'),
  valor: yup.mixed().transform((value: any) => tratandoValor(value)),
  processoSei: yup.string().required('é Obrigatório'),
  usuarioCreatedId: yup.string(),
});

export type RendimentoALocacao = {
  planoAplicacaoId: string;
  eixoFinanciadoId: string;
  conta: string;
  metaFisicaId: string;
  metaInstitucionalId: string;
  valorAlocado: any;
  processoSei: string;
};
