import { Box, Grid, Chip, Alert, styled, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { diferencaEmDias, getColor, tratandoData, tratandoValorCentavos } from 'src/config/utils';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CircleIcon from '@mui/icons-material/Circle';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import { getPlanoAplicacaoId } from 'src/services/Planejamento/FundoNacional/planoAplicacao';
import { getRendimentosPlano } from 'src/services/Planejamento/FundoNacional/rendimento';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';

const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: 700,
  color: '#555555',
  textTransform: 'uppercase',
}));
const TableCellBody = styled(TableCell)(({ theme }) => ({
  fontSize: '14px',
  color: '#555555',
}));

export default function PlanoAplicacaoVisualizar({ id }: { id: string }) {
  const include = ['eixoFinanciado', 'user'];
  const { data: rows, isPending } = useQuery({
    queryKey: ['getPlanoAplicacao', id, include],
    queryFn: getPlanoAplicacaoId,
  });

  const { data: getRendimentosPlanoID } = useQuery({
    queryKey: ['getRendimentosPlano', id],
    queryFn: getRendimentosPlano,
  });
  if (isPending) {
    return <ModalCarregamento isOpen={isPending} />;
  }

  return (
    <Box
      sx={{
        p: 1,
        margin: 'auto',
        mt: 3,
      }}
    >
      <CustomContainerList
        title="Plano de Aplicação do Fundo Nacional de Segurança Pública"
        type="detalhes"
      >
        <Grid container>
          <Grid item md={12} xs={12}>
            <TableContainer>
              <Table sx={{ minWidth: 700, mt: 2 }} aria-label="spanning table">
                <TableHead sx={{ background: '#f5f5f5' }}>
                  <TableRow>
                    <TableCellHead colSpan={4}>Exercício Orçamentário</TableCellHead>
                    <TableCellHead align="center">Prazo Para Execução</TableCellHead>
                    <TableCellHead align="center">Data Final do Prazo</TableCellHead>
                    <TableCellHead align="center">Situação</TableCellHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCellBody colSpan={4}>
                      {rows.planoAplicacao.exercicioOrcamentario}
                    </TableCellBody>
                    <TableCellBody align="center">
                      <Chip
                        sx={{ color: 'white', fontWeight: '700' }}
                        label={`${diferencaEmDias(rows.planoAplicacao.prazoExecucao)} Dias`}
                        color={getColor(diferencaEmDias(rows.planoAplicacao.prazoExecucao))}
                      />
                    </TableCellBody>
                    <TableCellBody align="center">
                      {tratandoData(rows.planoAplicacao.prazoExecucao)}
                    </TableCellBody>
                    <TableCellBody align="center">
                      <Chip
                        label={rows.planoAplicacao.status}
                        sx={{
                          color: 'white',
                          fontWeight: '700',
                          textTransform: 'uppercase',
                        }}
                        color={rows.planoAplicacao.status === 'Pendente' ? 'warning' : 'success'}
                      />
                      {}
                    </TableCellBody>
                  </TableRow>
                  <TableRow sx={{ background: '#f5f5f5' }}>
                    <TableCellHead>Eixos</TableCellHead>
                    <TableCellHead align="center">Valor Inicial de Investimento</TableCellHead>
                    <TableCellHead align="center">Valor Total de Investimento</TableCellHead>
                    <TableCellHead align="center">Conta Investimento</TableCellHead>

                    <TableCellHead align="center">Valor Inicial de Custeio</TableCellHead>
                    <TableCellHead align="center">Valor Total de Custeio</TableCellHead>
                    <TableCellHead align="center">Conta Custeio</TableCellHead>
                  </TableRow>
                  {rows.eixosFinanciados?.map((item: any, index: number) => (
                    <TableRow key={index}>
                      <TableCellBody>{item.eixoNome}</TableCellBody>
                      <TableCellBody align="center">
                        <>R$ {tratandoValorCentavos(item.valorInicialInvestimento)} </>
                      </TableCellBody>
                      <TableCellBody align="center">
                        <>
                          R${' '}
                          {tratandoValorCentavos(
                            (getRendimentosPlanoID?.results.find(
                              (conta: any) => conta.conta === item.contaInvestimento
                            )?.valorTotal || 0) + (item.valorInicialInvestimento || 0)
                          )}
                        </>
                      </TableCellBody>
                      <TableCellBody align="center">{item.contaInvestimento}</TableCellBody>

                      <TableCellBody align="center">
                        <> R$ {tratandoValorCentavos(item.valorInicialCusteio)} </>
                      </TableCellBody>
                      <TableCellBody align="center">
                        <>
                          R${' '}
                          {tratandoValorCentavos(
                            (getRendimentosPlanoID?.results.find(
                              (conta: any) => conta.conta === item.contaCusteio
                            )?.valorTotal || 0) + (item.valorInicialCusteio || 0)
                          )}
                        </>
                      </TableCellBody>
                      <TableCellBody align="center">{item.contaCusteio}</TableCellBody>
                    </TableRow>
                  ))}
                  <TableRow sx={{ background: '#f5f5f5' }}>
                    <TableCellHead colSpan={8}>Rendimentos</TableCellHead>
                  </TableRow>
                  <TableRow>
                    <TableCellHead colSpan={4}>Conta</TableCellHead>
                    <TableCellHead align="center">Valor Total</TableCellHead>
                    <TableCellHead align="center">Valor Retirado</TableCellHead>
                    <TableCellHead align="center">Valor Disponível</TableCellHead>
                  </TableRow>

                  {getRendimentosPlanoID?.results.map((item: any, index: number) => (
                    <TableRow key={index}>
                      <TableCellBody colSpan={4}>{item.conta}</TableCellBody>
                      <TableCellBody align="center">
                        <>R$ {tratandoValorCentavos(item.valorTotal)} </>
                      </TableCellBody>
                      <TableCellBody align="center">
                        <>R$ {tratandoValorCentavos(item.valorRetirado)} </>
                      </TableCellBody>
                      <TableCellBody align="center">
                        <>R$ {tratandoValorCentavos(item.valorDisponivel)} </>
                      </TableCellBody>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item md={12} xs={12}>
            <Box m={2}>
              <Typography sx={{ fontSize: '12px', m: 1, fontWeight: '700' }}>
                Prazo de Execução
              </Typography>
              <Chip
                icon={<CircleIcon color="error" sx={{ fontSize: '13px' }} />}
                label="Prazo excedido"
                variant="outlined"
                sx={{ border: 'none' }}
              />
              <Chip
                icon={<CircleIcon color="warning" sx={{ fontSize: '13px' }} />}
                label="Menos de 6 meses"
                variant="outlined"
                sx={{ border: 'none' }}
              />
              <Chip
                icon={<CircleIcon color="success" sx={{ fontSize: '13px' }} />}
                label="Mais de 6 meses"
                variant="outlined"
                sx={{ border: 'none' }}
              />
            </Box>
          </Grid>
          {rows.planoAplicacao.status === 'Autorizado' && (
            <Box my={2}>
              <Alert severity="info">Autorizado por : {rows.usuarioAuthorization.nome}</Alert>
            </Box>
          )}
        </Grid>
      </CustomContainerList>
    </Box>
  );
}
