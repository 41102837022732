import React, { createContext, useReducer, useEffect, useMemo } from 'react';
import { AuthState, User, initialState, AuthContextProps, AuthAction } from './types';

// REDUCER
const reducer = (state: AuthState, action: AuthAction) => {
  switch (action.type) {
    case 'Login':
      return {
        ...state,
        isAuth: true,
        usuario: action.payload?.usuario || null,
        isLoading: false,
      };
    case 'Logout':
      return {
        ...state,
        isAuth: false,
        usuario: null,
        isLoading: false,
      };
    case 'SidebarOpen':
      return {
        ...state,
        sidebarOpen: true,
      };
    case 'SidebarClose':
      return {
        ...state,
        sidebarOpen: false,
      };
    default:
      return state;
  }
};

// CONTEXT
export const AuthContext = createContext<AuthContextProps>({
  state: initialState,
  loginAuth: () => {},
  logout: () => {},
  openSidebar: () => {},
  closeSidebar: () => {},
});

// PROVIDER
interface AuthProviderProps {
  children: JSX.Element;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const usuario = localStorage.getItem('usuario');
    const sidebarOpen = localStorage.getItem('sidebarOpen') === 'true';
    if (usuario) {
      const userData: User = JSON.parse(usuario);
      dispatch({ type: 'Login', payload: { usuario: userData } });
    } else {
      dispatch({ type: 'Logout' });
    }
    dispatch({ type: sidebarOpen ? 'SidebarOpen' : 'SidebarClose' }); // Define o estado da sidebar
  }, []);

  const loginAuth = (UserData: User) => {
    localStorage.setItem('usuario', JSON.stringify(UserData));
    dispatch({
      type: 'Login',
      payload: { usuario: UserData },
    });
  };

  const logout = () => {
    localStorage.removeItem('usuario');
    dispatch({
      type: 'Logout',
    });
  };

  const openSidebar = () => {
    localStorage.setItem('sidebarOpen', 'true');
    dispatch({ type: 'SidebarOpen' });
  };

  const closeSidebar = () => {
    localStorage.setItem('sidebarOpen', 'false');
    dispatch({ type: 'SidebarClose' });
  };

  const contextValue = useMemo(
    () => ({
      state,
      loginAuth,
      logout,
      openSidebar,
      closeSidebar,
    }),
    [state]
  );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
