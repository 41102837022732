import { Container, Typography } from '@mui/material';
import { RoutePrivate } from 'src/Auth/RouteProtect';
import LayoutSidebar from 'src/components/Layout';
import { Despesas } from 'src/pages/Admin/Despesas';
import Permissoes from 'src/pages/Admin/Permissoes';
import Unidades from 'src/pages/Admin/Unidades';
import Usuarios from 'src/pages/Admin/Usuarios';
import CadastroUsuario from 'src/pages/Admin/Usuarios/Cadastro';

export const Admin = {
  element: <RoutePrivate element={<LayoutSidebar />} permission="ADMIN" />,
  path: '/administrador',
  children: [
    // ADMINISTRADOR
    {
      element: (
        <Container sx={{ textAlign: 'center' }}>
          <Typography variant="h5">Selecione um Módulo</Typography>
        </Container>
      ),
      path: '',
    },
    {
      element: <Usuarios />,
      path: 'usuarios',
    },
    {
      element: <CadastroUsuario />,
      path: 'usuarios/cadastro',
    },
    {
      element: <Unidades />,
      path: 'unidades',
    },
    {
      element: <Despesas />,
      path: 'despesas',
    },
    {
      element: <Permissoes />,
      path: 'permissoes',
    },
  ],
};
