import { useState } from 'react';
import { Box, Stack } from '@mui/material';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import { CustomContainerCad } from 'src/components/CustomContainer/custom-container';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormsProvider from 'src/components/Forms/Provider';
import { useMutation } from '@tanstack/react-query';
import { ToastFun } from 'src/config/functions';
import { postCadastrarUsuario } from 'src/services/Admin/post';
import {
  schemaUsuario,
  defaultValuesUsuario,
  dadosUsuario,
} from 'src/pages/Admin/Usuarios/typeUsuario';
import { ErroResponse } from 'src/pages/types';
import { useNavigate } from 'react-router-dom';
import FormularioCadastrarUsuario from './formularioUsuario';

export default function CadastroUsuario() {
  const navigate = useNavigate();
  const [funcoes, setFuncoes] = useState<string[]>([]);
  const [modulos, setModulos] = useState<string[]>([]);

  const methods = useForm({
    resolver: yupResolver(schemaUsuario),
  });

  const { reset, handleSubmit, watch, setValue } = methods;

  const { mutate, isPending } = useMutation({
    mutationFn: postCadastrarUsuario,
    onSuccess: (data) => {
      navigate('/administrador/usuarios');
      ToastFun('Usuário Cadastrado com sucesso', 'success');
      setFuncoes([]);
      setModulos([]);
    },
    onError: (error: ErroResponse) => {
      ToastFun(error.response.data?.message, 'error');
    },
  });
  const onSubmit = async (data: dadosUsuario) => {
    const permission: object[] = [];

    data.permissoes.forEach((item) => {
      let permiAdmin = {};
      let permiOther = {};
      if (item.funcao === 'administrador') {
        permiAdmin = { nome: 'admin' };
      } else {
        permiOther = { nome: `${item.funcao}_${item.modulo}` };
      }
      if (Object.keys(permiAdmin).length) {
        permission.push(permiAdmin);
      } else if (Object.keys(permiOther).length) {
        permission.push(permiOther);
      }
    });
    data.permissoesUsuario = permission;
    mutate(data);
  };
  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={5}>
        <CustomBreadcrumbs
          heading="Usuário"
          links={[
            { name: 'Administrador', href: '/administrador' },
            { name: 'Usuários', href: '/administrador/usuarios' },
            { name: 'Cadastro', href: '/administrador/usuarios/cadastro' },
          ]}
        />
        <CustomContainerCad title="Usuário">
          <FormsProvider methods={methods} handleSubmit={onSubmit}>
            <FormularioCadastrarUsuario
              setValue={setValue}
              funcoes={funcoes}
              setFuncoes={setFuncoes}
              setModulos={setModulos}
              modulos={modulos}
            />
          </FormsProvider>
        </CustomContainerCad>
      </Stack>
    </Box>
  );
}
