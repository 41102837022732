import { useState } from 'react';
import {
  Box,
  Grid,
  Stack,
  Button,
  IconButton,
  Chip,
  TextField,
  Grow,
  Modal,
  Typography,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import { useMutation, useQuery } from '@tanstack/react-query';
import TableContainer from 'src/components/Table';
import { GridColDef } from '@mui/x-data-grid';
import { tratandoData, tratandoValorCentavos } from 'src/config/utils';

import { getRemanejamentos } from 'src/services/Planejamento/FundoNacional/remanejamento';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import RemanejamentoVisualizar from '../Visualizar/Remanejamento';

export default function RemanejamentosAutorizacao() {
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 7,
  });

  const skip = paginationModel.page * paginationModel.pageSize;
  const take = paginationModel.pageSize;

  const [idRemanejamento, setIdRemanejamento] = useState<any>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalType, setmodalType] = useState<string>();
  const [checked, setChecked] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('Pendente');

  const {
    data: rows,
    refetch: RefecthRemanejamentos,
    isPending,
  } = useQuery({
    queryKey: ['getRemanejamento', skip, take, search],
    queryFn: getRemanejamentos,
  });
  if (isPending) {
    return <ModalCarregamento isOpen={isPending} />;
  }
  const handleButtonClick = (id: any, type: string) => {
    setIdRemanejamento(id);
    setIsModalOpen(true);
    setmodalType(type);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setIdRemanejamento(null);
  };

  const columns: GridColDef[] = [
    {
      field: 'natureza',
      headerName: 'NATUREZA',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => <>{params.row.metaFisicaRetirada.natureza}</>,
    },
    {
      field: 'metaFisicaRetirada',
      headerName: 'META FÍSICA - RETIRADA',
      flex: 2,
      minWidth: 150,

      renderCell: (params) => <>{params.row.metaFisicaRetirada.nome}</>,
    },

    {
      field: 'metaFisicaRecebida',
      headerName: 'META FÍSICA - RECEBIDA',
      flex: 2,
      minWidth: 150,

      renderCell: (params) => <>{params.row.metaFisicaRecebida.nome}</>,
    },

    {
      field: 'valorRemanejado',
      headerName: 'VALOR REMANEJADO',
      flex: 1,
      minWidth: 150,

      headerAlign: 'center',
      align: 'center',

      renderCell: (params) => <>R$ {tratandoValorCentavos(params.row.valorRemanejado)}</>,
    },

    {
      field: 'criadoEm',
      headerName: 'CRIADO EM ',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => tratandoData(params.row.criadoEm),
    },
    {
      field: 'usuarioCreate',
      headerName: 'CRIADO POR ',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => params.row.usuarioCreate.nome,
    },
    {
      field: 'status',
      headerName: 'SITUAÇÃO',
      flex: 1,
      minWidth: 150,

      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          sx={{ color: 'white' }}
          color={params.row.status === 'Pendente' ? 'warning' : 'success'}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Box>
          <IconButton
            color="primary"
            onClick={() => handleButtonClick(params.row.id, 'Visualizar')}
          >
            <VisibilityIcon />
          </IconButton>
        </Box>
      ),
    },
  ];

  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={2}>
        <Grid container spacing={2}>
          <Grid xs={12} mt={2}>
            <CustomContainerList title="Remanejamentos">
              {rows && (
                <TableContainer
                  columns={columns}
                  rows={rows.results}
                  rowCount={rows.total}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                />
              )}
            </CustomContainerList>
          </Grid>
        </Grid>
      </Stack>
      {idRemanejamento && (
        <RemanejamentoVisualizar
          open={isModalOpen}
          handleClose={handleCloseModal}
          id={idRemanejamento}
          RefecthRemanejamentos={RefecthRemanejamentos}
        />
      )}
    </Box>
  );
}
