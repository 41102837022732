import { Api } from '../Api';
import Endpoints from '../endpoints';

// Admin
interface DadosUsuario {
  id: string;
  permissao: {
    nome: string;
  }[];
}

export async function ExcluirPermissaoUsuario(dadosUsuario: DadosUsuario) {
  const response = await Api.delete(`${Endpoints.usuario}/${dadosUsuario.id}`, {
    data: {
      permissao: dadosUsuario.permissao,
    },
  });
  return response.data;
}
