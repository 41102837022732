import { Grid, Button, MenuItem } from '@mui/material';
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import SaveIcon from '@mui/icons-material/Save';

export default function AbastecimentoForms() {
  return (
    <Grid container spacing={2}>
      <Grid item md={4} xs={12}>
        {/* buscar empresa */}
        <TextFieldRG select label="Empresa" name="empresa">
          <MenuItem value="aeroMedicoBombeiro">Aeromédico/Bombeiro</MenuItem>
        </TextFieldRG>
      </Grid>
      {/* buscar combustivel */}

      <Grid item md={4} xs={12}>
        <TextFieldRG select label="Combustível" name="combustivel">
          <MenuItem value="ativo">Ativo</MenuItem>
        </TextFieldRG>
      </Grid>

      {/* buscar aeronave */}

      <Grid item md={4} xs={12}>
        <TextFieldRG select label="Aeronave" name="aeronaveId">
          <MenuItem value="ativo">Ativo</MenuItem>
        </TextFieldRG>
      </Grid>

      <Grid item md={4} xs={12}>
        <TextFieldRG type="date" label="Data de Abastecimento" name="dataAbastecimento" />
      </Grid>
      <Grid item md={4} xs={12}>
        <TextFieldRG label="Litros" name="litros" />
      </Grid>

      <Grid item xs={12} md={12} sx={{ textAlign: 'right' }}>
        <Button variant="contained" color="success" type="submit" startIcon={<SaveIcon />}>
          Cadastrar
        </Button>
      </Grid>
    </Grid>
  );
}
