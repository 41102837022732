import { useState } from 'react';
import { Box, Grid, Stack, Button, IconButton, Chip, TextField, Grow } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';
import CustomBreadcrumbs from 'src/components/CustomBreadcrumbs';
import { useQuery } from '@tanstack/react-query';
import TableContainer from 'src/components/Table';
import { GridColDef } from '@mui/x-data-grid';
import { SomaTotal, tratandoData, tratandoValorCentavos } from 'src/config/utils';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SearchIcon from '@mui/icons-material/Search';
import { Navigate, useNavigate } from 'react-router-dom';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import { getPlanoAplicacao } from 'src/services/Planejamento/FundoNacional/planoAplicacao';

export default function PlanoAplicacaoTabelaAutorizacao() {
  const navigate = useNavigate();
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 8,
  });

  const skip = paginationModel.page * paginationModel.pageSize;
  const take = paginationModel.pageSize;

  const [id, setId] = useState<string | null>(null);
  const [search, setSearch] = useState<string>('Pendente');
  const [checked, setChecked] = useState<boolean>(false);
  const include = ['eixoFinanciadoCount', 'user'];
  const {
    data: rows,
    refetch: RefecthPlano,
    isPending,
  } = useQuery({
    queryKey: ['getPlanoAplicacao', skip, take, search, include],
    queryFn: getPlanoAplicacao,
  });
  if (isPending) {
    return <ModalCarregamento isOpen={isPending} />;
  }

  const columns: GridColDef[] = [
    {
      field: 'exercicioOrcamentario',
      headerName: 'EXERCÍCIO ORÇAMENTÁRIO',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: '_count',
      headerName: 'EIXOS FINANCIADOS',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => <>{params.row.dadosEixosFinanciados._count.id}</>,
    },
    {
      field: 'prazoExecucao',
      headerName: 'PRAZO DE EXECUÇÃO',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => tratandoData(params.row.prazoExecucao),
    },
    {
      field: 'usuarioCreate',
      headerName: 'CADASTRADO POR',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => params.row.usuarioCreate.nome,
    },
    {
      field: 'criadoEm',
      headerName: 'CADASTRADO EM',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => tratandoData(params.row.criadoEm),
    },
    {
      field: 'usuarioUpdate',
      headerName: 'ATUALIZADO POR',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) =>
        params.row.usuarioUpdateId ? params.row.usuarioUpdate.nome : 'Não Atualizado',
    },
    {
      field: 'atualizadoEm',
      headerName: 'ATUALIZADO EM',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) =>
        params.row.usuarioUpdate ? tratandoData(params.row.atualizadoEm) : 'Não Atualizado',
    },

    {
      field: 'status',
      headerName: 'SITUAÇÃO',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Chip
          label={params.row.status}
          sx={{ color: 'white' }}
          color={params.row.status === 'Pendente' ? 'warning' : 'success'}
        />
      ),
    },
    {
      field: 'actions',
      headerName: '',
      flex: 1,
      minWidth: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <Box>
          <IconButton color="primary" onClick={() => navigate(`plano/${params.row.id}`)}>
            <VisibilityIcon />
          </IconButton>
        </Box>
      ),
    },
  ];
  const inputSearch = () => {
    if (checked) {
      setSearch('');
    }
    setChecked((prev: boolean) => !prev);
  };
  return (
    <Box sx={{ m: 'auto' }}>
      <Stack spacing={2}>
        <Grid container spacing={2}>
          <Grid xs={12} mt={2}>
            <CustomContainerList title="Plano de Aplicação ">
              {rows && (
                <TableContainer
                  columns={columns}
                  rows={rows.results}
                  rowCount={rows.total}
                  paginationModel={paginationModel}
                  onPaginationModelChange={setPaginationModel}
                />
              )}
            </CustomContainerList>
          </Grid>
        </Grid>
      </Stack>
    </Box>
  );
}
