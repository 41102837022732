import { Alert, Box, Grid, styled } from '@mui/material';
import DrawIcon from '@mui/icons-material/Draw';
import { useQuery } from '@tanstack/react-query';
import { tratandoValorCentavos } from 'src/config/utils';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ModalCarregamento from 'src/components/Modal/Carregamento';
import { getMetaFisica } from 'src/services/Planejamento/FundoNacional/metas';
import { CustomContainerList } from 'src/components/CustomContainer/custom-container';

const TableCellHead = styled(TableCell)(({ theme }) => ({
  fontSize: '11px',
  fontWeight: 700,
  color: '#555555',
  textTransform: 'uppercase',
}));
const TableCellBody = styled(TableCell)(({ theme }) => ({
  fontSize: '14px',
  color: '#555555',
  textTransform: 'uppercase',
}));

export default function MetaFisicaVisualizaDetalhes({ id }: { id: string }) {
  const include = ['metaFisicaCompleto', 'metasInstituicoes'];
  const { data: rowsMeta, isPending } = useQuery({
    queryKey: ['getMetaFisica', id, include],
    queryFn: () => getMetaFisica(id, include),
  });

  if (isPending) {
    return <ModalCarregamento isOpen={isPending} />;
  }

  return (
    <Box
      sx={{
        p: 2,

        margin: 'auto',
        mt: 1,
      }}
    >
      <Grid container>
        <Grid md={12} xs={12}>
          <CustomContainerList title="Meta Física" type="detalhes">
            <TableContainer>
              {/* DADOS DA META - ANO - EIXO -META GERAL */}
              <Table sx={{ minWidth: 700, mt: 2 }} aria-label="spanning table">
                <TableHead sx={{ background: '#f5f5f5' }}>
                  <TableRow>
                    <TableCellHead>Exercicío Orçamentário</TableCellHead>
                    <TableCellHead>Eixo Financiado</TableCellHead>
                    <TableCellHead colSpan={3}>Meta Geral</TableCellHead>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCellBody>{rowsMeta.planoAplicacao.exercicioOrcamentario}</TableCellBody>
                    <TableCellBody>{rowsMeta.eixoFinanciado.eixoNome}</TableCellBody>
                    <TableCellBody colSpan={3}>{rowsMeta.metaGeral.nome}</TableCellBody>
                  </TableRow>
                  <TableRow sx={{ background: '#f5f5f5' }}>
                    <TableCellHead colSpan={2}>Meta Física</TableCellHead>
                    <TableCellHead>Natureza</TableCellHead>
                    <TableCellHead colSpan={2}>Tipo de Despesa</TableCellHead>
                  </TableRow>
                  <TableRow>
                    <TableCellBody colSpan={2}>{rowsMeta.nome}</TableCellBody>
                    <TableCellBody>{rowsMeta.natureza}</TableCellBody>
                    <TableCellBody colSpan={2}>{rowsMeta.tipoDespesaData.nome}</TableCellBody>
                  </TableRow>
                  <TableRow sx={{ background: '#f5f5f5' }}>
                    <TableCellHead colSpan={2}>INSTITUIÇÃO BENEFICIADA</TableCellHead>
                    <TableCellHead>VALOR INICIAL</TableCellHead>
                    <TableCellHead>UNIDADE</TableCellHead>
                  </TableRow>
                  {rowsMeta.metasInstituicoes.map((item: any, index: number) => (
                    <TableRow key={index}>
                      <TableCellBody colSpan={2}>{item.nome}</TableCellBody>
                      <TableCellBody>R$ {tratandoValorCentavos(item.valorInicial)}</TableCellBody>
                      <TableCellBody>
                        {item.unidade ? item.unidade : 'Unidade Não Atribuida'}
                      </TableCellBody>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              {/* DADOS DA META FISICA -INSTITUIÇÕES */}
            </TableContainer>
          </CustomContainerList>
        </Grid>
      </Grid>
    </Box>
  );
}
