import { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
//
import TextFieldRG from 'src/components/Forms/TextFieldRG';
import { getInstituicoes, getTipoDespesas, getUnidadesInstituicao } from 'src/services/Admin/get';
import { useQueries, useQuery } from '@tanstack/react-query';
import { NumericFormat } from 'react-number-format';
import { ToastFun } from 'src/config/functions';
import {
  FieldValues,
  UseFormWatch,
  UseFormSetValue,
  UseFormUnregister,
  useWatch,
} from 'react-hook-form';
import { InstituicaoBeneficiada } from './InstituicaoBeneficiada';

interface DadosInstituicao {
  id: string;
  sigla: string;
  nome: string;
  valorInicial: number;
}

interface Metas {
  index: number;
  setValue: UseFormSetValue<FieldValues>;
  unregister: UseFormUnregister<FieldValues>;
  excluirMetaFisica: (id: number) => void;
  watch: any;
}

export function MetaFisicaForms({ index, setValue, unregister, excluirMetaFisica, watch }: Metas) {
  const todasInsst = watch(`metasFisicas[${index}].metasInstituicoes`);

  const [instituicaoBeneficiada, setInstituicaoBeneficiada] = useState<number[]>([0]);

  const novaInstituicao = () => {
    const novasInstituicoes = [...instituicaoBeneficiada];
    const count = novasInstituicoes[novasInstituicoes.length - 1] + 1;
    setInstituicaoBeneficiada([...novasInstituicoes, count]);
  };
  const excluirInstituicao = (indexInst: number) => {
    if (instituicaoBeneficiada.length > 1) {
      unregister(`metasFisicas[${index}].metasInstituicoes[${indexInst}]`);
      setInstituicaoBeneficiada((prevInst) => prevInst.filter((inst) => inst !== indexInst));
    } else ToastFun('É necessário que a Meta possua ao menos um isntituição', 'error');
  };

  const { data: rowsTipoDespesa } = useQuery({
    queryKey: ['getTipoDespesas'],
    queryFn: getTipoDespesas,
  });

  const { data: rowInstituicoes } = useQuery({
    queryKey: ['getInstituicoes'],
    queryFn: getInstituicoes,
  });

  useEffect(() => {
    if (todasInsst?.length > 0) {
      const eixoAtualizar: number[] = [];
      todasInsst.map((_item: any, indexInst: number) => eixoAtualizar.push(indexInst));
      setInstituicaoBeneficiada(eixoAtualizar);
    }
  }, [todasInsst]);
  return (
    <Grid container spacing={2} mb={2} p={1}>
      <Grid item xs={12} md={12}>
        <Box
          px={1}
          my={1}
          sx={{ background: '#eef7f1', borderRadius: '5px', px: 1, color: 'green' }}
        >
          <Grid container>
            <Grid item md={6} xs={10}>
              <Box display="grid">
                <Typography variant="subtitle2" sx={{ py: 1 }}>
                  Meta Física
                </Typography>
              </Box>
            </Grid>
            <Grid item md={6} xs={2} sx={{ textAlign: 'right', py: 0.5 }}>
              <IconButton color="error" size="small" onClick={() => excluirMetaFisica(index)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12} md={12}>
        <TextFieldRG
          multiline
          type="text"
          name={`metasFisicas[${index}].nome`}
          label="Meta Física"
        />
      </Grid>
      {/* SELECT NATUREZA DESPESA */}
      <Grid item xs={12} md={6}>
        <TextFieldRG select label="Natureza Despesa" name={`metasFisicas[${index}].natureza`}>
          <MenuItem value="investimento">Investimento</MenuItem>
          <MenuItem value="custeio">Custeio</MenuItem>
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextFieldRG select label="Tipo de Despesa" name={`metasFisicas[${index}].tipoDespesa`}>
          {rowsTipoDespesa?.results.map((item: any) => (
            <MenuItem key={item.id} value={item.id}>
              {item.nome}
            </MenuItem>
          ))}
        </TextFieldRG>
      </Grid>
      <Grid item xs={12} md={12}>
        <Stack direction="row" spacing={4}>
          <Typography variant="subtitle2" sx={{ color: '#0d5b01', py: 1 }}>
            Instituições Beneficiadas
          </Typography>
          <Button variant="soft" color="info" startIcon={<AddIcon />} onClick={novaInstituicao}>
            Instituição
          </Button>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        {instituicaoBeneficiada.map((item, indexInstituicao) => (
          <InstituicaoBeneficiada
            key={item}
            indexInstituicao={item}
            index={index}
            rowInstituicoes={rowInstituicoes}
            instituicaoBeneficiada={instituicaoBeneficiada}
            setValue={setValue}
            excluirInstituicao={excluirInstituicao}
          />
        ))}
      </Grid>
    </Grid>
  );
}
